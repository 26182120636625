
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import { RankRequirementsType, RankType } from '@/api/graphQL/graphNodes/types';
import dashboardStats from '@/modules/Dashboard/stats';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class BonusQualifiedTeam extends Vue {
    @Get(dashboardStats) private stats!: RankRequirementsType[];

    @Prop() private rank!: RankType;

    @Prop() private requirements!: RankRequirementType[];

    get metric(): string {
        return RankRequirementManager.rankRequirementMetricConstants.ambassadorLegs;
    }

    get ambassadorLegsCount(): number {
        const stat = this.stats.find((item) => item.metric === this.metric);
        return !stat ? 0 : Number(stat.value);
    }

    get title(): string {
        return RankRequirementManager
            .getRankRequirementLabel(this.metric, this.ambassadorLegsCount).title;
    }

    get ambassadorLegsCountRequirement(): RankRequirementType | null {
        const requirement = this.requirements
            .find((item) => item.metric === this.metric);

        return requirement || null;
    }

    get progressValue(): number|null {
        const x = this.ambassadorLegsCount * 100;
        const ambassadorLegsCount = this.ambassadorLegsCountRequirement;

        if (!ambassadorLegsCount) {
            return null;
        }

        if (Number(ambassadorLegsCount.value) === 0) {
            return 0;
        }

        return x / Number(ambassadorLegsCount.value);
    }

    get displayData() {
        const requirement = this.ambassadorLegsCountRequirement;

        if (!requirement) {
            return false;
        }

        return true;
    }
}
