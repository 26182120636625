import { MeType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetMeQuery extends AbstractQueryResource<GetMeResultType> {
    protected getQuery(): string {
        return `query ${this.getName()} {
            me {
                roles,
                userName,
            }
            profile {
                shopUrl,
                shopLoginUrl,
                suspension,
                vanityUrl,
            }
            eventGuestsInviteOptionEnabled
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getMe';
    }
}

export type GetMeResultType = {
    me: MeType;
    profile: {
        shopUrl: string;
        shopLoginUrl: string;
        suspension: boolean;
        vanityUrl: string;
    }
    eventGuestsInviteOptionEnabled: boolean;
}
