const descriptionResolver: (type: string) => string = (type) => {
    let res = '';

    switch (type) {
    case 'kit_boost_uk':
        res = 'Requires £360 Personal Sales in 21 days period since joining';
        break;
    case 'kit_boost_non_uk':
        res = 'Requires £360 Personal Sales in 28 period days since joining';
        break;
    case 'kit_refund_uk':
        res = 'Requires £720 Personal Sales in 42 days period since joining';
        break;
    case 'kit_refund_non_uk':
        res = 'Requires 720 Personal Sales in 49 days period since joining';
        break;
    case 'business_boost_uk':
        res = 'Requires 2 New Recruits in 63 days period since joining';
        break;
    case 'business_boost_non_uk':
        res = 'Requires 2 New Recruits in 70 days period since joining';
        break;
    case 'double_promotion':
        res = 'Requires Promoting to Team Leader Title in 3 calendar months since joining';
        break;
    default:
        break;
    }

    return res;
};

export default descriptionResolver;
