import API from '@/api/graphQL';
import { UpdateBusinessDetailsPropType } from '@/api/graphQL/graphNodes/UpdateBusinessDetailsQuery';

export default class NoPermissionRepository {
    static getTermsAndConditions() {
        return API
            .getTermsAndConditions()
            .query();
    }

    static updateTermsAndConditions() {
        return API
            .updateTermsAndConditions()
            .query();
    }

    static getProfileInfo() {
        return API
            .getProfileMinimal()
            .query();
    }

    static getUnderInspectionData() {
        return API
            .getUnderInspectionData()
            .query();
    }

    static getBusinessDetails() {
        return API
            .noPermissionBusinessDetails()
            .query();
    }

    static updateBankDetails(data) {
        return API
            .updateBankDetails()
            .query(data);
    }

    static updateBusinessDetails(data: UpdateBusinessDetailsPropType) {
        return API
            .updateBusinessDetails()
            .query(data);
    }
}
