import store from '@/store';
import { CollectionModule, CollectionVuexModule } from '@/utils/vuex-module-mutators';
import { getModule, Mutation } from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { defaultMinimalParentLeg } from '../defaults';
import risingStarsRepository from './services/risingStarsRepository';
import { LegType } from '../types';

export class Leg {
    id: string = '';

    data: LegType = defaultMinimalParentLeg() as LegType;

    loading: boolean = false;
}

@CollectionModule({
    namespaced: true, dynamic: true, store, name: 'risingStarsLeg', item: Leg,
})
class RisingStarsLegModule extends CollectionVuexModule<Leg> {
    @Mutation
    public setLoading(params: { id: string, val: boolean }) {
        const { id, val } = params;

        if (this.collection[id]) {
            this.collection[id].loading = val;
        }
    }

    @Mutation
    public setData(params: { id: string, val: LegType}) {
        const { id, val } = params;

        if (this.collection[id]) {
            this.collection[id].data = { ...this.collection[id].data, ...val };
        }
    }

    @Action()
    public async loadData(id) {
        try {
            this.setLoading({ id, val: true });
            const res = await risingStarsRepository.getLeg({ id });
            this.setData({ id, val: res.enrollee as LegType });
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading({ id, val: false });
        }
    }
}

export default getModule(RisingStarsLegModule);
