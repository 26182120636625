
import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import system from '@/modules/System';

@Component
export default class SubScene extends Vue {
    @Prop({ required: true }) private route!: string;

    @Prop({ default: false }) private withoutPadding!: boolean;

    @Get(system, 'route') private currentRoute!: any;

    get elementClasses() {
        return [
            'SubScene__Container',
            { 'SubScene__Container--open': this.matchingRoute },
        ];
    }

    get elementContentClasses() {
        return [
            'SubScene__Content',
            { 'SubScene__Content--noPadding': this.withoutPadding },
        ];
    }

    get matchingRoute() {
        // @ts-ignore
        const { name, path } = this.currentRoute;

        if (this.route === name || this.route === path || this.matchPath()) {
            return true;
        }
        return false;
    }

    @Watch('$route', { immediate: true, deep: true })
    handleOpenRoute() {
        if (this.matchingRoute) {
            this.$emit('onEnter');
        }
    }

    matchPath() {
        return this.currentRoute.matched.find((item) => item.path === this.route);
    }
}
