import {
    ProfileType, ProfileAmbassadorType, BankDetailsType, BusinessDetailsType,
    UploadProfilePictureType,
} from '@/api/graphQL/graphNodes/types';
import { COUNTRY_SELECT_OPTIONS } from '../constants';

const defaultBankDetails: () => BankDetailsType = () => ({
    bankAccountName: '',
    bankAccountNumber: '',
    bankSortCode: '',
});

const defaultBusinessDetails: () => BusinessDetailsType = () => ({
    businessAccount: false,
    businessAddressLookup: '',
    businessAddress: '',
    businessName: '',
    eoriNumber: '',
    tinNumber: '',
    vatNumber: '',
});

const profile: () => ProfileType = () => ({
    id: '',
    parentId: '',
    address: '',
    email: '',
    phoneNumber: '',
    phoneNumberCode: '',
    joinDate: '',
    kitPurchaseDate: '',
    paidForKit: false,
    rank: {
        id: '',
        label: '',
    },
    profilePicture: {
        link: '',
        context: [],
    },
    firstName: '',
    lastName: '',
    secondAddress: '',
    birthDate: '',
    postCode: '',
    town: '',
    county: '',
    country: COUNTRY_SELECT_OPTIONS.gb,
    region: COUNTRY_SELECT_OPTIONS.gb,
    bankDetails: {
        bankAccountName: '',
        bankAccountNumber: '',
        bankSortCode: '',
    },
    businessDetails: defaultBusinessDetails(),
});

const initialRegistrationData: () => ProfileAmbassadorType = () => ({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    repeatPassword: '',
    address: '',
    secondAddress: '',
    phoneNumber: '',
    birthDate: '',
    postCode: '',
    town: '',
    termsChecked: false,
    county: '',
    country: COUNTRY_SELECT_OPTIONS.gb,
    bankDetails: defaultBankDetails(),
    businessDetails: defaultBusinessDetails(),
});

const defaultProfilePictureData: () => UploadProfilePictureType = () => ({
    link: '',
    context: [],
});

export {
    profile, initialRegistrationData, defaultBankDetails,
    defaultProfilePictureData, defaultBusinessDetails,
};
