import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ErrorType } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import componentsControl from '@/modules/ComponentsControls';
import { INVITATION_SENT_SUCCESSFULLY } from '@/utils/messages/formValidation';
import InviteRepository from './services/inviteRepository';
import invitationFormValidation from './services/invitationFormValidation';
import { InvitationFormType } from './types';
import { initialInvitationData } from './defaults';
import { FORM_ERROR_TOOLTIP } from '../constants';
import { ObjectPropertyType } from '../types';

@Module({
    namespaced: true, dynamic: true, store, name: 'invite',
})
@AutoMutations
export class Invite extends VuexModule {
    private formData: InvitationFormType = initialInvitationData();

    private formErrors: ErrorType = {};

    private invitationLink: string = '/';

    private loading: boolean = false;

    private inviteLoader: boolean = false;

    private displayTooltip: boolean = false;

    @Mutation
    public setDisplayTooltip(val: boolean) {
        this.displayTooltip = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setInviteLoader(val: boolean) {
        this.inviteLoader = val;
    }

    @Mutation
    public resetData() {
        this.formData = initialInvitationData();
    }

    @Mutation
    public setFormError(payload: ObjectPropertyType) {
        this.formErrors = {
            ...this.formErrors,
            [payload.key]: payload.val,
        };
    }

    @Mutation
    public setFormErrors(errors: any) {
        this.formErrors = { ...errors };
    }

    @Mutation
    public clearFormErrors() {
        this.formErrors = {};
    }

    @Mutation
    public setInvitationLink(val: string) {
        this.invitationLink = val;
    }

    @Mutation
    public clearFormData() {
        this.formData = { ...initialInvitationData() };
    }

    @Action()
    public validateInvitationForm() {
        this.clearFormErrors();
        const formErrors = invitationFormValidation(this.formData);
        formErrors.forEach((error) => this.setFormError(error));
    }

    @Action()
    public displayFormErrorsTooltip() {
        this.setDisplayTooltip(true);
        setTimeout(() => {
            this.setDisplayTooltip(false);
        }, FORM_ERROR_TOOLTIP.timeOutInterval);
    }

    @Action()
    public async getInvitationLink() {
        try {
            this.setLoading(true);
            const data = await InviteRepository.getInvitationUrl();
            this.setInvitationLink(data.profile.vanityUrl);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async inviteAmbassador() {
        try {
            this.validateInvitationForm();

            if (Object.keys(this.formErrors).length === 0) {
                this.setInviteLoader(true);
                await InviteRepository.inviteAmbassador({ ...this.formData });
                componentsControl.showSuccessMessage({ message: INVITATION_SENT_SUCCESSFULLY });
                this.resetData();
            } else {
                this.displayFormErrorsTooltip();
            }
        } catch (e) {
            const errors = ErrorsProcessor.process(e);
            this.setFormErrors(errors.form);
            this.displayFormErrorsTooltip();
        } finally {
            this.setInviteLoader(false);
        }
    }
}

export default getModule(Invite);
