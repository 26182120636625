// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { NewStartersOptionsType } from './types';

export default class GetNewStartersSearchOptionsQuery extends
    AbstractQueryResource<GetNewStartersSearchOptionsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($date: DateTime!, $type: String!, $query: String) {
            profile {
                searchNewStarters(date: $date, type: $type, query: $query) {
                    firstName,
                    lastName,
                },
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getNewStartersSearchOptions';
    }
}

export type GetNewStartersSearchOptionsResultType = {
    profile: {
        searchNewStarters: NewStartersOptionsType[];
    }
}

export type GetNewStartersSearchOptionsParamsType = {
    date: string;
    type: string;
    query: string|null;
}
