import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ObjectProcessor } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { DownlineExplorerType, DownlineLegType } from '@/api/graphQL/graphNodes/types';
import {
    GetDownlineResultType, ExploreDownlineParamsType, ExploreDownlineSearchParamsType,
    GetDownlineSearchResultType,
} from '@/api/graphQL/graphNodes/GetDownlineQuery';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import downlineExplorerRepository from './services/DownlineExplorerRepository';
import { defaultDownlineExplorerData } from './defaults';
import legModule, { Leg } from './leg';

@Module({
    namespaced: true, dynamic: true, store, name: 'downlineExplorer',
})
@AutoMutations
export class DownlineExplorer extends VuexModule {
    private data: DownlineExplorerType = defaultDownlineExplorerData();

    private headerDetailsOpen: boolean = false;

    private activeLegDetailsDialog: string = '';

    private level: number = 0;

    private loaded: boolean = false;

    private loading: boolean = false;

    private loadingInBackground: boolean = false;

    private expandedDownline: string[] = [];

    private offset: number = 0;

    private limit: number = 20;

    private activeSponsorDetailsModal: string = '';

    private searchQuery: string = '';

    private searchLoading: boolean = false;

    private searchedLegsOptions: DownlineLegType[] = [];

    private searchedLegs: DownlineLegType[] = [];

    private minSearchLength: number = 3;

    private displaySearchResults: boolean = false;

    private filter: string|null = null;

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setHeaderDetailsOpen(val: boolean) {
        this.headerDetailsOpen = val;
    }

    @Mutation
    public setDownlineExplorerData(val: DownlineExplorerType) {
        this.data = { ...val };
    }

    @Mutation
    public addToDirectDownline(val: DownlineLegType[]) {
        this.data.directDownline = [...this.data.directDownline, ...val];
    }

    @Mutation
    public clearDirectDownline() {
        this.data.directDownline = [];
        this.data.directDownlineCount = 0;
    }

    @Mutation
    public setLevel(val: number) {
        this.level = val;
    }

    @Mutation
    public setActiveLegDetailsDialog(val: string) {
        this.activeLegDetailsDialog = val;
    }

    @Mutation
    toggleExpandedList(val: string) {
        if (this.expandedDownline.find((item) => item === val)) {
            const index = this.expandedDownline.findIndex((item) => item === val);
            this.expandedDownline.splice(index, 1);
        } else {
            this.expandedDownline.push(val);
        }
    }

    @Mutation
    clearExpandedList() {
        this.expandedDownline = [];
    }

    @Mutation
    setOffset(offset: number) {
        this.offset = offset;
    }

    @Mutation
    setActiveSponsorDetailsModal(val: string) {
        this.activeSponsorDetailsModal = val;
    }

    @Mutation
    setSearchLoading(val: boolean) {
        this.searchLoading = val;
    }

    @Mutation
    setSearchedLegs(val: DownlineLegType[]) {
        this.searchedLegs = val;
    }

    @Mutation
    setSearchedOptionsLegs(val: DownlineLegType[]) {
        this.searchedLegsOptions = val;
    }

    @Mutation
    setSearchQuery(val: string = '') {
        this.searchQuery = val;
    }

    @Mutation
    setDisplaySearchResults(val: boolean) {
        this.displaySearchResults = val;
    }

    @Mutation
    setFilter(val: string|null) {
        this.filter = val;
    }

    @Action()
    public async getDownlineData(data: { id: string, offset?: number, loadPage?: boolean }) {
        const { id, loadPage = false, offset = 0 } = data;

        try {
            if (loadPage) {
                this.setLoadingInBackground(true);
            } else {
                this.setLoading(true);
            }

            const params: ExploreDownlineParamsType = ObjectProcessor.removeEmptyProperties({
                id,
                limit: this.limit,
                offset,
                aofFilter: this.filter,
            });

            const res: GetDownlineResultType = await downlineExplorerRepository
                .getData(params) as GetDownlineResultType;
            const { enrollee, breadcrumbs } = res.exploreEnrollee;

            this.setSearchedOptionsLegs([]);

            legModule.setElements([]);

            enrollee.directDownline
                .sort((a, b) => (a.totals.pv < b.totals.pv ? 1 : -1));

            enrollee.directDownline.forEach((star) => {
                this.handleLegCreate(star);
            });

            this.setDownlineExplorerData(enrollee);
            this.setOffset(offset);

            if (!loadPage) {
                this.setLevel(breadcrumbs.length - 1);
                this.clearExpandedList();
            }

            this.setLoaded(true);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (loadPage) {
                this.setLoadingInBackground(false);
            } else {
                this.setLoading(false);
            }
        }
    }

    @Action()
    async search(data: { query?: string|null, selectedSearch?: boolean }
    = { query: null, selectedSearch: false }) {
        const { query, selectedSearch } = data;
        try {
            if (selectedSearch) {
                this.setSearchLoading(true);
            }

            const params: ExploreDownlineSearchParamsType = {
                id: this.data.id,
                query: query || query === '' ? query : this.searchQuery,
                aofFilter: this.filter,
            };

            const result: GetDownlineSearchResultType = await downlineExplorerRepository
                .searchData(params) as GetDownlineSearchResultType;

            const { searchDirectDownline } = result.exploreEnrollee.enrollee;

            if (selectedSearch) {
                searchDirectDownline.forEach((star) => {
                    if (!legModule.collection[star.id] && selectedSearch) {
                        this.handleLegCreate(star);
                    }
                });

                this.setSearchedLegs(searchDirectDownline);
            } else {
                this.setSearchedOptionsLegs(searchDirectDownline);
            }
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (selectedSearch) {
                this.setSearchLoading(false);
            }
        }
    }

    @Action()
    handleLegCreate(star: DownlineLegType) {
        const leg = new Leg();
        leg.id = star.id;
        leg.data = { ...leg.data, ...star };

        legModule.addElement(leg);
        legModule.loadData(star.id);
    }
}

export default getModule(DownlineExplorer);
