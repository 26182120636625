
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { SelectOptionsType } from '@/components/select/types';
import { Debounced } from '@/utils/debounced';

@Component
export default class SearchSelect extends Vue {
    @Prop({ default: '' }) private id!: string;

    @Prop({ default: '' }) private label!: string;

    @Prop({ default: '' }) private helperText!: string;

    @Prop({ required: true }) private value!: string;

    @Prop({ default: '' }) private error!: string;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ default: 'No options found.' }) private noOptionsText!: string;

    @Prop({ required: true }) private options!: SelectOptionsType[];

    @Prop({ default: 0 }) private minSearchLength!: number;

    @Prop({ default: true }) private required!: boolean;

    @Prop({ default: false }) private loading!: boolean;

    @Prop({ default: '' }) private icon!: string;

    private containerClass: string[] = [];

    private open: boolean = false;

    private infoTextOpen: boolean = false;

    private clickOutsideEvent: Function = () => {};

    private openingElement: any = null;

    private focused: boolean = false;

    get elementClasses() {
        return [
            'SearchSelect',
            ...this.containerClass,
            { 'SearchSelect--error': this.error !== '' },
            { 'SearchSelect--disabled': this.disabled },
            { 'SearchSelect--withIcon': this.icon !== '' },
        ];
    }

    optionalLabel(val: string) {
        return `${val} (optional)`;
    }

    addToContainerClass(items: string[]) {
        const combinedObject = new Set([...this.containerClass, ...items]);
        this.containerClass = [...combinedObject];
    }

    removeFromContainerClass(items: string[]) {
        this.containerClass = this.containerClass.filter((classItem) => !items.includes(classItem));
    }

    elementFocused(focused: boolean) {
        this.focused = focused;
        this.setActive(focused);
        if (focused) {
            this.addToContainerClass(['SearchSelect--focused']);
        } else {
            this.removeFromContainerClass(['SearchSelect--focused']);
        }
    }

    setActive(active: boolean) {
        const el: HTMLInputElement = this.$refs.searchInputContainer as HTMLInputElement;

        if (active) {
            this.addToContainerClass(['SearchSelect--isPulled']);
        } else if (el.value === '') {
            this.removeFromContainerClass(['SearchSelect--isPulled', 'SearchSelect--isFilled']);
        } else {
            this.addToContainerClass(['SearchSelect--isFilled']);
        }
    }

    openSelect(): void {
        if (!this.disabled) {
            if (this.open) {
                this.closeSelect();
                return;
            }

            this.setOutsideClickListener();
            this.open = true;
        }
    }

    closeSelect() {
        this.open = false;
    }

    closeWithEsc() {
        this.closeFormDataSelectContainer();
        this.elementFocused(false);
    }

    openInfo() {
        if (!this.disabled && this.options.length === 0 && this.value.length
         >= this.minSearchLength) {
            this.setOutsideClickListener();
            this.infoTextOpen = true;
        }
    }

    closeInfo() {
        this.infoTextOpen = false;
    }

    created() {
        if (this.value !== '') {
            this.addToContainerClass(['SearchSelect--isFilled', 'SearchSelect--isPulled']);
        }
    }

    handleInput(val: string) {
        this.$emit('input', val);
        this.handleSubmit();
    }

    handleSelect(val: SelectOptionsType) {
        const { clickable } = val;
        if (clickable !== false) {
            this.$emit('onSelect', val);
        }
    }

    handleKeyboardSelect(val) {
        this.handleSelect(val);
        this.closeWithEsc();
    }

    @Debounced(1000)
    handleSubmit() {
        this.$emit('onSubmit', this.value);
    }

    setOutsideClickListener(): void {
        const element = this.$refs.searchInputContainer;
        if (this.openingElement === element) {
            return;
        }

        this.openingElement = element;

        this.clickOutsideEvent = (event: Event) => {
            const searchInputEl: HTMLElement = this.$refs.searchInputContainer as HTMLElement;
            const selectedEventNode: Node = event.target as Node;

            if (!(
                searchInputEl === event.target
                || searchInputEl.contains(selectedEventNode)
            )) {
                this.closeFormDataSelectContainer();
            }
        };

        document.addEventListener('click', this.clickOutsideEvent as EventListener);
    }

    closeFormDataSelectContainer(): void {
        this.closeSelect();
        this.closeInfo();
        this.openingElement = null;
        document.removeEventListener('click', this.clickOutsideEvent as EventListener);
    }

    @Watch('options')
    handleLoadedOptions(val, oldVal) {
        if (oldVal.length === 0 && val.length !== 0) {
            this.setActive(true);
        }
    }

    beforeUpdate() {
        if (this.value === '') {
            this.removeFromContainerClass(['SearchSelect--isFilled', 'SearchSelect--isPulled']);
        } else {
            this.addToContainerClass(['SearchSelect--isFilled', 'SearchSelect--isPulled']);
        }

        if (this.focused) {
            this.closeSelect();

            if (this.options.length > 0) {
                this.openSelect();
                this.closeInfo();
            } else {
                this.openInfo();
            }
        }
    }
}
