
import { Vue, Component } from 'vue-property-decorator';
import auth from '@/modules/Auth';

@Component
export default class HelpAndSupport extends Vue {
    get customerServiceClasses() {
        return [
            { 'Ui__VerticalDivider--xsmall': this.isHostess },
            'pts-layout-item pts-size-100 Skeleton__Animation HelpAndSupport__CustomerServiceContainer SkeletonHelp__Service',
        ];
    }

    get containerClasses() {
        return [
            { HelpAndSupport__Hostess: this.isHostess },
            'Ui__Card pts-layout pts-gutter HelpAndSupport__Container',
        ];
    }

    get isHostess(): boolean {
        return auth.isHostess;
    }
}
