import API from '@/api/rest';
import { ResponseErrorsProcessor } from '@plumtreesystems/utils';
import {
    ACCESS_FORBIDDEN_TNC, ACCESS_FORBIDDEN_KIT, ACCESS_FORBIDDEN_ID_CHECK,
    ACCESS_FORBIDDEN_BUSINESS_DETAILS,
} from './constants';

const handled403Types = [ACCESS_FORBIDDEN_TNC, ACCESS_FORBIDDEN_KIT, ACCESS_FORBIDDEN_ID_CHECK,
    ACCESS_FORBIDDEN_BUSINESS_DETAILS];

const registerError = (e: any) => {
    API.registerError().registerFailure(e);
};

export default new ResponseErrorsProcessor(registerError, handled403Types);
