
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { Debounced } from '@/utils/debounced';
import orders from '@/modules/Orders';
import system from '@/modules/System';
import { SelectOptionsType } from '@/components/select/types';
import { OrderType } from '@/api/graphQL/graphNodes/types';

@Component
export default class OrdersHeader extends Vue {
    @Sync(orders) private searchQuery!: string;

    @Get(orders) private searchLoading!: boolean;

    @Get(orders) private minSearchLength!: number;

    @Get(orders) private searchedOrdersOptions!: OrderType[];

    @Get(orders) private displaySearchResults!: boolean;

    @Get(orders) private displaySearch!: boolean;

    @Get(system) private screenType!: string;

    get searchOptions(): SelectOptionsType[] {
        return this.searchedOrdersOptions.map((order) => ({
            name: `#${order.id}`,
            value: `#${order.id}`,
        }));
    }

    get showSearch() {
        return this.screenType !== 'mobile' || this.displaySearch;
    }

    get searchContainerClasses() {
        return [
            'pts-layout-item',
            'pts-size-100',
            'pts-small-size-100',
            'OrdersHeader__Container',
        ];
    }

    handleSelect(val) {
        orders.setSearchQuery(val.value);
        orders.setDisplaySearchResults(true);
        orders.search({ selectedSearch: true });
    }

    @Debounced(1000)
    handleChange() {
        if (this.searchQuery === '') {
            orders.setDisplaySearchResults(false);
            orders.setSearchedOrdersOptions([]);
            orders.setSearchedOrders([]);
            orders.getOrders({});
        }
        if (this.searchQuery.length >= this.minSearchLength) {
            orders.search();
        }
    }
}
