import { BUSINESS_SHAPE_TYPE } from '@/modules/BusinessShape/constants';
import { StatsType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class GetAmbassadorsCountsQuery extends
    AbstractQueryResource<GetAmbassadorsCountsResultType> {
    private activeLevelsQuery = `
        ambassadorsCountsByActivityLevel(type: "${BUSINESS_SHAPE_TYPE.active}") {
            metric,
            value
        },
    `;

    private activeOnFileLevelsQuery = `
        ambassadorsCountsByActivityLevel(type: "${BUSINESS_SHAPE_TYPE.active_on_file}") {
            metric,
            value
        },
    `;

    private inactiveLevelsQuery = `
        ambassadorsCountsByActivityLevel(type: "${BUSINESS_SHAPE_TYPE.inactive}") {
            metric,
            value
        },
    `;

    protected getQuery(): string {
        const query = `query ${this.getName()}($id: String!) {
            active: enrollee (id: $id) {
                ${this.activeLevelsQuery}
            }
            activeOnFile: enrollee (id: $id) {
                ${this.activeOnFileLevelsQuery}
            }
            inactive: enrollee (id: $id) {
                ${this.inactiveLevelsQuery}
            }
        }`;

        return query;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getAmbassadorsCounts';
    }
}

export type GetAmbassadorsCountsResultType = {
    active: GetAmbassadorsCountByLevelType;
    activeOnFile: GetAmbassadorsCountByLevelType;
    inactive: GetAmbassadorsCountByLevelType;
}

export type GetAmbassadorsCountByLevelType = {
    ambassadorsCountsByActivityLevel: StatsType[];
}

export type AmbassadorsCountsParamsType = {
    id: string;
    from: string;
    to: string;
}
