
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';
import helpAndSupport from '@/modules/HelpAndSupport';
import helpAndSupportAssets from '@/modules/HelpAndSupport/assets';
import { SponsorType } from '@/modules/HelpAndSupport/types';
import env from '@/environment';
import auth from '@/modules/Auth';
import Skeleton from './Skeleton/index.vue';
import HelpAndSupportAssets from './AmbassadorAssets/index.vue';
import HelpAndSupportHostessAssets from './HostessAssets/index.vue';

@Component({
    components: {
        PropertyInfoDisplay,
        HelpAndSupportAssets,
        HelpAndSupportHostessAssets,
        Skeleton,
    },
})
export default class HelpAndSupport extends Vue {
    @Get(helpAndSupport) private sponsor!: SponsorType;

    @Get(helpAndSupport) private uplineLeader!: SponsorType;

    @Get(helpAndSupport, 'loading') private loadingData!: boolean;

    @Get(helpAndSupportAssets, 'loading') private loadingAssets!: boolean;

    get loading() {
        return this.loadingData || this.loadingAssets;
    }

    get isAmbassador(): boolean {
        return auth.isAmbassador;
    }

    get isHostess(): boolean {
        return auth.isHostess;
    }

    get sponsorLabel(): string {
        if (this.isHostess) {
            return 'My partner';
        }

        return 'My upline';
    }

    get customerServiceClasses() {
        return [
            { 'Ui__VerticalDivider--xsmall': this.isHostess },
            'pts-layout-item pts-size-100 HelpAndSupport__CustomerServiceContainer',
        ];
    }

    get containerClasses() {
        return [
            { HelpAndSupport__Hostess: this.isHostess },
            'Ui__Card pts-layout pts-gutter HelpAndSupport__Container',
        ];
    }

    checkIfDataAvailable(data: any): boolean {
        if (!data || data === '') {
            return false;
        }

        return true;
    }

    beforeMount() {
        helpAndSupport.getSponsorData();
        // TODO: remove after fixing impersonate
        helpAndSupportAssets.setOffset();

        if (this.isAmbassador) {
            helpAndSupportAssets.getFiles({});
        } else {
            helpAndSupportAssets.getHostessFiles({});
        }
    }

    openKnowledgeBase() {
        if (this.isAmbassador) {
            window.open(env.VUE_APP_FAQ_URL, '_blank');
        } else {
            window.open(env.VUE_APP_HOSTESS_FAQ_URL, '_blank');
        }
    }
}
