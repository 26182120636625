import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { BankDetailsType } from '@/api/graphQL/graphNodes/types';
import noPermissionRepository from './services/noPermissionRepository';
import { initialRegistrationBankDetailsData } from '../Register/defaults';
import { ObjectPropertyType } from '../types';
import { bankDetailsValidation } from '../Register/services/registrationFormValidation';

@Module({
    namespaced: true, dynamic: true, store, name: 'underInspection',
})
@AutoMutations
export class UnderInspection extends VuexModule {
    private region: string|null = null;

    private loading: boolean = false;

    private data: BankDetailsType = initialRegistrationBankDetailsData();

    private formErrors: ErrorType = {};

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setRegion(val: string|null) {
        this.region = val;
    }

    @Mutation
    public setFormError(payload: ObjectPropertyType) {
        this.formErrors = {
            ...this.formErrors,
            [payload.key]: payload.val,
        };
    }

    @Mutation
    public setFormErrors(errors: any) {
        this.formErrors = { ...errors };
    }

    @Mutation
    public clearFormErrors() {
        this.formErrors = {};
    }

    @Mutation
    public removeFormError(key) {
        const { formErrors } = this;
        delete formErrors[key];
        this.formErrors = { ...formErrors };
    }

    @Mutation
    public setFormData(data: BankDetailsType) {
        this.data = ObjectProcessor.objectMerge(initialRegistrationBankDetailsData(), data);
    }

    @Mutation
    public resetFormData() {
        this.data = { ...initialRegistrationBankDetailsData() };
    }

    @Action()
    public clearData() {
        this.setLoading(false);
    }

    @Action()
    public async getData() {
        try {
            this.setLoading(true);
            const res = await noPermissionRepository.getUnderInspectionData();
            this.setRegion(res.profile.region);
        } catch (e) {
            const errors = ErrorsProcessor.process(e);
            this.setFormErrors(errors.form);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async saveData() {
        try {
            this.setLoading(true);

            this.clearFormErrors();
            const formErrors = bankDetailsValidation(this.data);

            if (formErrors.length > 0) {
                formErrors.forEach((error) => this.setFormError(error));
            } else {
                await noPermissionRepository.updateBankDetails({ bankDetails: this.data });
            }
        } catch (e) {
            const errors = ErrorsProcessor.process(e);
            this.setFormErrors(errors.form);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(UnderInspection);
