import graphAPI from '@/api/graphQL';
import API from '@/api/rest';
import env from '@/environment';
import auth from '@/modules/Auth';
import noPermission from '@/modules/NoPermission';
import LocalStorageManager from '@/utils/local-storage-manager';
import { errorsFormatter, RequestCache } from '@plumtreesystems/utils';
import {
    ACCESS_FORBIDDEN_TNC, ACCESS_FORBIDDEN_KIT, ACCESS_FORBIDDEN_ID_CHECK,
    ACCESS_FORBIDDEN_BUSINESS_DETAILS,
} from '@/utils/responseErrorsProcessor/constants';
import store from '@/store';
import impersonation from '@/modules/Impersonation';
import authLocalStorageManager from './authLocalStorageManager';

class Authenticator {
    private redirectPath = 'auth';

    private authPath;

    private clientId;

    private router;

    private authenticated: boolean = false;

    private mockAuthenticator = env.VUE_APP_MOCK_AUTHENTICATOR;

    private handled403Types = [
        ACCESS_FORBIDDEN_TNC, ACCESS_FORBIDDEN_KIT, ACCESS_FORBIDDEN_ID_CHECK,
        ACCESS_FORBIDDEN_BUSINESS_DETAILS,
    ];

    constructor() {
        this.authPath = env.VUE_APP_AUTH_URL;
        this.clientId = env.VUE_APP_AUTH_CLIENT_ID;
    }

    get getAuthenticated() {
        return this.authenticated;
    }

    setRouter(router) {
        this.router = router;
    }

    getRedirectUrl() {
        const parts = window.location.href.split('/');
        const host = `${parts[0]}//${parts[2]}`;
        return `${host}/${this.redirectPath}`;
    }

    getLogoutUrl() {
        const parts = window.location.href.split('/');
        const host = `${parts[0]}//${parts[2]}`;
        return host;
    }

    startLogin() {
        if (this.mockAuthenticator === 'false') {
            const url = `${this.authPath}?client_id=${this.clientId}&redirect_uri=${this.getRedirectUrl()}`;
            window.location.replace(url);
        } else {
            authLocalStorageManager.setAuthToken('mock');
            this.router.push({ name: 'dashboard' })
                .catch(() => {});
        }
    }

    async finishLogin() {
        LocalStorageManager.clearImpersonateDeprecatedData();
        LocalStorageManager.clearStoreDeprecatedData();
        RequestCache.clearRequestCache();
        RequestCache.clearImpersonateCache();
        const authToken = this.handleToken() || '';
        await this.setAuthTokens(authToken);
        authLocalStorageManager.setAuthToken(authToken);
        await auth.setupUserDetails(impersonation.impersonating);
    }

    handleToken(): string|null {
        const url = new URL(window.location.href);
        return url.searchParams.get('token');
    }

    async logout(withRedirect: boolean = true, customRedirectName: string|null = null) {
        const currentToken = authLocalStorageManager.getAuthToken();
        await API.setAuth('');
        await graphAPI.setToken('');
        authLocalStorageManager.removeToken();
        this.authenticated = false;

        LocalStorageManager.clearImpersonateDeprecatedData();
        LocalStorageManager.clearStoreDeprecatedData();
        LocalStorageManager.clearStoreStateVersion();
        RequestCache.clearRequestCache();
        RequestCache.clearImpersonateCache();

        const defaultStoreState = LocalStorageManager.getStoreDefaultState();
        store.replaceState({ ...store.state, ...defaultStoreState });
        if (withRedirect) {
            if (customRedirectName) {
                this.router.push({ name: customRedirectName });
            } else if (this.mockAuthenticator === 'false') {
                const url = `${this.authPath}?client_id=`
            + `${this.clientId}&redirect_uri=${this.getLogoutUrl()}&logout=true&token=${currentToken}`;
                window.location.replace(url);
            } else {
                this.router.push({ name: 'login' });
            }
        }
    }

    async onTenantDisabledError() {
        await API.setAuth('');
        await graphAPI.setToken('');
        this.router.push({ name: 'authError' });
    }

    async onAccountDisabledError() {
        await API.setAuth('');
        await graphAPI.setToken('');
        this.router.push({ name: 'authError' });
    }

    async handle401() {
        this.logout();
    }

    async handle403(err) {
        const errors = errorsFormatter(err, this.handled403Types);

        // This part handled authentication errors, but now with more complex error handling
        // it's should be useless, but to be sure if all scenarios are covered and this code
        // should be deleted it is left commented for some time.

        // if (auth.authFailHandled) {
        //     return;
        // }

        // auth.setAuthFailHandled(true);

        if (err?.response?.data
            && /^Tenant.*disabled$/.test(err.response.data)
        ) {
            this.onTenantDisabledError();
        } else if (err?.response?.data
            && /^Account.*disabled$/.test(err.response.data)
        ) {
            this.onAccountDisabledError();
        } else if (Object.keys(errors.forbidden).length === 0) {
            this.logout();
        } else {
            noPermission.clearErrors();
            noPermission.setPermissionErrors(errors.forbidden);
            this.router.push({ name: 'noPermission' });
        }
    }

    // register token and hooks with API
    async initAuth() {
        API.on('401', () => { this.handle401(); });

        API.on('403', (err) => { this.handle403(err); });

        graphAPI.on('401', () => { this.handle401(); });

        graphAPI.on('403', (err) => { this.handle403(err); });
    }

    async handleUserDataLoading(route) {
        auth.setAuthenticationInProgress(true);

        const authToken = authLocalStorageManager.getAuthToken();

        const publicRouteToken = route.query.token;

        if (!authToken && publicRouteToken) {
            await this.finishLogin();
            auth.setAuthenticationInProgress(false);
            this.authenticated = true;
        } else {
            await this.setAuthTokens(authToken);
            auth.setAuthenticationInProgress(false);

            if (authToken) {
                await auth.setupUserDetails(impersonation.impersonating);
                this.authenticated = true;
            }
        }

        if (this.mockAuthenticator === 'true') {
            await auth.setupUserDetails(impersonation.impersonating);
            this.authenticated = true;
        }
    }

    async setAuthTokens(authToken: string|null) {
        if (authToken) {
            await this.setAPIAuthToken(authToken);
        }
    }

    async setAPIAuthToken(token: string) {
        await API.setAuth(() => token);
        await graphAPI.setToken(() => token);
    }

    get authTokenFromStorage() {
        return authLocalStorageManager.getAuthToken();
    }
}

export default new Authenticator();
