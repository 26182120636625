import { ResolverReportType } from '../types';

const reportList: ResolverReportType[] = [
    {
        downloadUrl: 'https://plumtreesystems.com/assets/images/logo.png',
        fileName: 'test report',
        forRun: true,
        runId: '2',
    },
    {
        downloadUrl: 'https://plumtreesystems.com/assets/images/logo.png',
        fileName: 'test report2',
        forRun: false,
        runId: '2',
    },
    {
        downloadUrl: 'https://plumtreesystems.com/assets/images/logo.png',
        fileName: 'test report3',
        forRun: false,
        runId: '2',
    },
];

const reports: (runId: string|null) => ResolverReportType[] = (runId) => reportList
    .filter((item) => {
        if (runId === null) {
            return item.runId === '2';
        }

        return item.runId === runId;
    });

export { reports };
