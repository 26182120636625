import dateManager from '@/utils/time';
import { EventFormType } from '../types';

const formatEventDates: (event: EventFormType) => EventFormType = (event) => {
    const dateFormat = dateManager.getDayTimeFormatUtc();

    return {
        ...event,
        campaignDateFrom: dateManager.getDateTime(event.campaignDateFrom, dateFormat),
        campaignDateTo: dateManager.getDateTime(event.campaignDateTo, dateFormat),
        eventDateFrom: dateManager.getDateTime(event.eventDateFrom, dateFormat),
        eventDateTo: dateManager.getDateTime(event.eventDateTo, dateFormat),
    };
};

export default formatEventDates;
