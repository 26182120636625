
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { Debounced } from '@/utils/debounced';
import myCustomers from '@/modules/MyCustomers';
import system from '@/modules/System';
import { SelectOptionsType } from '@/components/select/types';
import { MyCustomersType } from '@/api/graphQL/graphNodes/types';
import DirectContainer from './DirectContainer/index.vue';

@Component({
    components: {
        DirectContainer,
    },
})
export default class MyCustomersHeader extends Vue {
    @Sync(myCustomers) private customersSearchQuery!: string;

    @Get(myCustomers) private customersSearchLoading!: boolean;

    @Get(myCustomers) private minSearchLength!: number;

    @Get(myCustomers) private searchedCustomersOptions!: MyCustomersType[];

    @Get(myCustomers) private displayCustomersSearch!: boolean;

    @Get(system) private screenType!: string;

    get searchOptions(): SelectOptionsType[] {
        return this.searchedCustomersOptions.map((customer) => ({
            name: `${customer.firstName} ${customer.lastName}`,
            value: `${customer.firstName} ${customer.lastName}`,
        }));
    }

    get showSearch() {
        return this.screenType !== 'mobile' || this.displayCustomersSearch;
    }

    get searchContainerClasses() {
        return [
            'pts-layout-item',
            'pts-size-50',
            'pts-small-size-100',
            'MyCustomersHeader__Container',
            { 'MyCustomersHeader__Container--show': this.showSearch },
        ];
    }

    handleSelect(val) {
        myCustomers.setCustomerSearchQuery(val.value);
        myCustomers.setDisplayCustomersSearchResults(true);
        this.searchCustomers();
    }

    searchCustomers() {
        myCustomers.searchCustomers({ selectedSearch: true });
    }

    @Debounced(1000)
    handleChange() {
        if (this.customersSearchQuery === '') {
            myCustomers.setDisplayCustomersSearchResults(false);
            myCustomers.setSearchedCustomersOptions([]);
            myCustomers.getMyCustomers({});
        }
        if (this.customersSearchQuery.length >= this.minSearchLength) {
            myCustomers.searchCustomers();
        }
    }
}
