
import Vue from 'vue';
import Component from 'vue-class-component';
import authenticator from '@/modules/Auth/services/authenticator';
import { RETURN_ROUTE_LOCAL_STORAGE_KEY } from '@/modules/System/constants';
import localStorageManager from '@/utils/local-storage-manager';

@Component
export default class Login extends Vue {
    get loader(): boolean {
        return !!localStorageManager.getStorage(RETURN_ROUTE_LOCAL_STORAGE_KEY);
    }

    login() {
        authenticator.startLogin();
    }
}
