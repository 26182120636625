
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { Debounced } from '@/utils/debounced';
import newStartersOrders from '@/modules/NewStarters/orders';
import { SelectOptionsType } from '@/components/select/types';
import { OrderType } from '@/api/graphQL/graphNodes/types';

@Component
export default class NewPartnersOrdersHeader extends Vue {
    @Sync(newStartersOrders) private searchQuery!: string;

    @Get(newStartersOrders) private searchLoading!: boolean;

    @Get(newStartersOrders) private minSearchLength!: number;

    @Get(newStartersOrders) private searchedOrdersOptions!: OrderType[];

    get customerId() {
        const { id } = this.$route.params;
        return id;
    }

    get searchOptions(): SelectOptionsType[] {
        return this.searchedOrdersOptions.map((order) => ({
            name: `#${order.id}`,
            value: `#${order.id}`,
        }));
    }

    get searchContainerClasses() {
        return [
            'pts-layout-item',
            'pts-size-100',
        ];
    }

    handleSelect(val) {
        newStartersOrders.setSearchQuery(val.value);
        newStartersOrders.setDisplaySearchResults(true);
        newStartersOrders.searchOrders({ enrolleeId: this.customerId, selectedSearch: true });
    }

    @Debounced(1000)
    handleChange() {
        if (this.searchQuery === '') {
            newStartersOrders.setDisplaySearchResults(false);
            newStartersOrders.setSearchedOrdersOptions([]);
            newStartersOrders.setSearchedOrders([]);
            newStartersOrders.getOrders({ enrolleeId: this.customerId });
        }
        if (this.searchQuery.length >= this.minSearchLength) {
            newStartersOrders.searchOrders({ enrolleeId: this.customerId });
        }
    }
}
