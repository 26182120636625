import parentObject from './reusable/listItemEnrolleeParent';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { GenealogyEnrolleeType } from './types';

export const GENEALOGY_ENROLLEE_PARENT_TYPES = {
    full: 'full',
    identity: 'identity',
};
export default class GetGenealogyEnrolleeQuery extends
    AbstractQueryResource<GetGenealogyEnrolleeResultType> {
    protected getQuery(options): string {
        const {
            withParent = true,
            parentType = GENEALOGY_ENROLLEE_PARENT_TYPES.full,
        } = options;

        const parent = parentObject.getQuery(parentType === GENEALOGY_ENROLLEE_PARENT_TYPES.full);

        return `query ${this.getName()}($id: String!) {
            enrollee(id: $id) {
                id,
                totals {
                    pv,
                    gv,
                    ov,
                },
                leaderLegsCount,
                ambassadorLegsCount,
                email,
                phoneNumber,
                secondPhoneNumber,
                secondAddress,
                county,
                country,
                town,
                postCode,
                ${withParent ? parent : ''}
                address,
                firstName,
                lastName,
                personalRecruitsCount,
                qualifiedBranchesCount,
                joinDate,
                payRank {
                    id,
                    label,
                    power,
                },
                rank {
                    id,
                    label,
                    power,
                },
                stats {
                    metric,
                    value,
                },
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getGenealogyEnrollee';
    }
}

export type GetGenealogyEnrolleeParamsType = {
    id: string;
}

export type GetGenealogyEnrolleeResultType = {
    enrollee: GenealogyEnrolleeType;
}
