import { CountriesType } from '@/modules/Event/types';
import countries from '@/modules/Countries';

const resolveRegionRequirement: (region: string) => string[] = (region) => {
    const regionObject: CountriesType|undefined = countries.regionsList
        .find((item) => item.shortLabel === region);

    const requiredBusinessDetails = regionObject ? regionObject.requiredBusinessDetails : [];

    return requiredBusinessDetails;
};

export default resolveRegionRequirement;
