const report = `type Report {
    type: String!
    fileName: String!
    downloadUrl: String!
    forRun: Boolean!
}`;

export {
    report,
};
