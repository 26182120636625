import API from '@/api/graphQL';
import restAPI from '@/api/rest';
import { CancelEventParamsType } from '@/api/graphQL/graphNodes/CancelEventQuery';
import { CreateEventParamsType } from '@/api/graphQL/graphNodes/CreateEventQuery';
import { EditEventParamsType } from '@/api/graphQL/graphNodes/EditEventQuery';
import { InviteEventGuestParamsType } from '@/api/graphQL/graphNodes/InviteGuestQuery';
import { GetHostsParamsType } from '@/api/graphQL/graphNodes/GetHostsQuery';
import { ShareEventParamsType } from '@/api/graphQL/graphNodes/ShareEventQuery';
import { RespondToInvitationParamsType } from '@/api/graphQL/graphNodes/RespondToInvitationQuery';
import { GetEventParamsType } from '@/api/graphQL/graphNodes/GetEventQuery';
import { GetEventConfirmationParamsType } from '@/api/graphQL/graphNodes/GetEventConfirmationQuery';
import { EventInviteType } from '../../EventInvitation/types';

export default class EventRepository {
    static getEvent(params: GetEventParamsType) {
        return API
            .getEvent()
            .query({ id: params.id });
    }

    static getEventHostesses(params: GetHostsParamsType) {
        return API
            .getHosts()
            .query(params);
    }

    static createEvent(event: CreateEventParamsType) {
        return API
            .createEvent()
            .query({ event });
    }

    static editEvent(id, event: EditEventParamsType) {
        return API
            .editEvent()
            .query({ id, event });
    }

    static getEventInviteLink(data: ShareEventParamsType, token?: string) {
        return API
            .shareEvent()
            .query(data, { token });
    }

    static inviteEventGuest(data: InviteEventGuestParamsType) {
        return API
            .inviteGuest()
            .query(data);
    }

    static cancelEvent(data: CancelEventParamsType) {
        return API
            .cancelEvent()
            .query(data);
    }

    static getInvitationForEvent(eventId: string) {
        return API
            .getInvitationForEvent()
            .query({ eventId });
    }

    static respondToInvitation(params: RespondToInvitationParamsType, token: string) {
        return API
            .respondToInvitation()
            .query({ ...params }, { token });
    }

    static getEventInvitation(id: string, token: string) {
        return API
            .getEventInvitation()
            .query({ id }, { token });
    }

    static customerEventInvite(eventToken: string, data: EventInviteType) {
        return restAPI
            .sendEventInvite()
            .sendInvitation(eventToken, data);
    }

    static getPublicEventConfirmation(props: GetEventConfirmationParamsType, token: string) {
        return API
            .getEventConfirmation()
            .query({ id: props.id }, { token });
    }

    static getTermsAndConditions(token: string, params: any) {
        return API
            .getTermsAndConditions()
            .query({ ...params }, { token });
    }
}
