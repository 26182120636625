const event = `type Event {
    id: String!,
    title: String!,
    address: String!,
    videoUrl: String,
    description: String!,
    allowGuestsToInvite: Boolean!,
    invitations: [EventGuest],
    inviteLink: String,
    campaignDateFrom: String,    
    campaignDateTo: String,
    eventDateFrom: String,    
    eventDateTo: String,
    ambassador: Enrollee,
    hostess: Enrollee,
    eventOrders(offset: Int, limit: Int): [Sale],
    eventOrdersCount: Int!,
    confirmed: Boolean!,
    canceled: Boolean!,
    closed: Boolean!,
    type: String!,
    shopUrl: String!,
    eventCode: String!,
    totalSales: Float!,
    shippingAddress: EventShippingAddress!
}`;

const eventGuest = `type EventGuest {
    id: String!,
    enrollee: Enrollee,
    attending: String,
    name: String,
    type: String!,
    email: String
}`;

const eventShippingAddress = `type EventShippingAddress {
    title: String,
    firstName: String!,
    lastName: String!,
    phoneNumber: String!,
    postcode: String!,
    address: String!,
    secondAddress: String,
    city: String!,
    county: String,
    country: String!
}`;

const hostessInvitationInput = `input HostessInvitationInput {
    email: String,
    firstName: String,
    lastName: String,
    phoneNumber: String,
    address: String,
    id: String,
    type: String,
}`;

const eventShippingAddressInput = `input EventShippingAddressInput {
    title: String,
    firstName: String!,
    lastName: String!,
    phoneNumber: String!,
    postcode: String!,
    address: String!,
    secondAddress: String,
    city: String!,
    county: String,
    country: String!
}`;

const eventEditInput = `input EventEditInput {
    title: String,
    eventDateFrom: String,
    eventDateTo: String,
    campaignDateFrom: String,
    campaignDateTo: String,
    address: String,
    videoUrl: String,
    description: String,
    allowGuestsToInvite: Boolean,
    shippingAddress: EventShippingAddressInput!
}`;

const eventCreateInput = `input EventCreateInput {
    title: String!,
    eventDateFrom: String!,
    eventDateTo: String!,
    campaignDateFrom: String!,
    campaignDateTo: String!,
    address: String,
    videoUrl: String,
    description: String!,
    type: String,
    canceled: Boolean!,
    allowGuestsToInvite: Boolean!,
    hostessInvitation: HostessInvitationInput,
    shippingAddress: EventShippingAddressInput!
}`;

const eventQuery = `
    events(date: DateTime, limit: Int!, offset: Int!, type: String!): [Event]
    event(id: String!): Event
    shareEvent(id: String!): String
    invitationForEvent (eventId: String!): Invitation
`;

const eventMutations = `
    createEvent (event: EventCreateInput): Event
    cancelEvent (id: String!): Event
    editEvent (id: String!, event: EventEditInput): Event
    inviteGuest (eventId: String!, invitation: GuestInvitationType): Invitation
    respondToInvitation (eventId: String!, attending: String!): Invitation
`;

const guestInvitationInput = `input GuestInvitationType {
    email: String!,
    firstName: String!,
    lastName: String!,
}`;

const eventInvitation = `type Invitation {
    id: String!
    email: String,
    enrollee: Enrollee,
    attending: String,
    address: String,
    name: String,
    firstName: String,
    lastName: String,
    phoneNumber: String,
    invitedBy: Enrollee!,
    invitedAt: DateTime!,
    respondedAt: DateTime,
    type: String!,
}`;

export {
    event, eventQuery, eventMutations, eventEditInput, eventCreateInput, eventGuest,
    hostessInvitationInput, guestInvitationInput, eventInvitation, eventShippingAddress,
    eventShippingAddressInput,
};
