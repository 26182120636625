
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import businessShape from '@/modules/BusinessShape';
import profile from '@/modules/Profile';
import Skeleton from '@/projectComponents/skeleton/Ambassadors/index.vue';
import { RankRequirementsType } from '@/api/graphQL/graphNodes/types';
import BusinessShapeItem from '../item.vue';

@Component({
    components: {
        BusinessShapeItem,
        Skeleton,
    },
})
export default class ActiveAmbassadorsBusinessShape extends Vue {
    @Get(profile, 'data.id') private myId!: string;

    @Get(businessShape) private loading!: boolean;

    @Get(businessShape, 'activeBusinessShapeData.active') private active!: RankRequirementsType[];

    @Get(businessShape, 'activeBusinessShapeData.activeOnFile') private activeOnFile!: RankRequirementsType[];

    @Get(businessShape, 'activeBusinessShapeData.inactive') private inactive!: RankRequirementsType[];

    beforeMount() {
        businessShape.getActiveAmbassadorsCounts(this.myId);
    }
}
