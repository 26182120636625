
import { Component, Vue, Watch } from 'vue-property-decorator';
import businessDetails from '@/modules/NoPermission/businessDetails';
import { Get } from '@/utils/vuex-module-mutators';
import { ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { BankDetailsType } from '@/api/graphQL/graphNodes/types';
import FormFieldTooltip from '@/projectComponents/formFieldTooltip/index.vue';
import addressSelect from '@/modules/AddressSelect';
import AddressSelect from '@/projectComponents/addressSelect/index.vue';
import PropertyInfoDisplay from '@/projectComponents/propertyInfoDisplay/index.vue';
import countries from '@/modules/Countries';
import resolveRegionRequirement from '@/modules/Countries/services/resolveRegionRequirement';

@Component({
    components: {
        FormFieldTooltip,
        AddressSelect,
        PropertyInfoDisplay,
    },
})
export default class NoPermissionBusinessDetails extends Vue {
    @Get(businessDetails) private loading!: boolean;

    @Get(countries, 'loading') private regionsLoading!: boolean;

    @Get(businessDetails) private data!: BankDetailsType;

    @Get(businessDetails) private region!: string;

    @Get(businessDetails) private formErrors!: ErrorType;

    @Get(addressSelect) searchWord!: string;

    get typePersonal(): boolean {
        return false;
    }

    get typeBusiness(): boolean {
        return true;
    }

    get regionRequirements(): string[] {
        return resolveRegionRequirement(this.region);
    }

    get viewLoading(): boolean {
        return this.loading || this.regionsLoading;
    }

    handleValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.data);
        businessDetails.removeFormError(`businessDetails.${key}`);
        if (key === 'businessName') {
            businessDetails.removeFormError('businessDetails.businessAddressLookup');
        }
        businessDetails.setFormData(res);
    }

    handleBusinessType(val: string) {
        const res: boolean = val === 'true';

        businessDetails.clearFormErrors();
        businessDetails.setBusinessAccount(res);
    }

    async handleSubmit() {
        try {
            await businessDetails.saveData();

            if (Object.keys(this.formErrors).length === 0) {
                this.$router.push({ name: 'dashboard' });
            }
        // eslint-disable-next-line no-empty
        } catch (e) {}
    }

    @Watch('searchWord')
    clearAddressError() {
        businessDetails.removeFormError('businessDetails.businessAddressLookup');
        businessDetails.removeFormError('businessDetails.businessName');
    }

    created() {
        businessDetails.resetFormData();
        businessDetails.setRegion('');
        businessDetails.getData();
        countries.getRegions();
    }
}
