
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import nextRankResolver from '@/modules/Rank/services/nextRankResolver';
import dashboard from '@/modules/Dashboard';
import dashboardStats from '@/modules/Dashboard/stats';
import { RankType, RunsType } from '@/api/graphQL/graphNodes/types';
import { rank as defaultRank } from '@/modules/Rank/defaults';
import { SelectOptionsType } from '@/components/select/types';
import RanksSkeleton from '../DashboardSkeleton/RanksElement.vue';
import Stats from '../Stats/index.vue';

@Component({
    components: {
        Stats,
        RanksSkeleton,
    },
})
export default class RankContainer extends Vue {
    @Sync(dashboardStats) private selectedRun!: string;

    @Get(dashboard, 'data.participatedRuns') private participatedRuns!: RunsType[];

    @Get(dashboardStats) private ranks!: RankType[];

    @Get(dashboard) private activeTab!: string;

    @Get(dashboardStats, 'loading') private statsLoading!: boolean;

    @Get(dashboardStats, 'loaded') private statsLoaded!: boolean;

    @Get(dashboardStats) private commissionRunRankId!: string;

    get currentRank(): RankType {
        return this.ranks.find((rankItem) => rankItem.id === this.commissionRunRankId)!;
    }

    get nextRank(): RankType {
        if (this.currentRank === null) {
            return defaultRank();
        }

        const nextRankItem = nextRankResolver(this.currentRank.power, this.ranks);

        return nextRankItem || defaultRank();
    }

    get nextRankTitle(): string {
        const { label } = this.nextRank;
        if (label !== '') {
            return label;
        }

        return '-';
    }

    get options(): SelectOptionsType[] {
        return this.participatedRuns.map((item, index) => ({
            name: index === 0 ? `${item.label} (Current)` : item.label,
            value: item.id,
        }));
    }

    handleSelect() {
        dashboardStats.getStats();
    }

    tabChanged(val: string) {
        dashboard.setActiveTab(val);
    }
}
