
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import { AchievementAwardType } from '@/api/graphQL/graphNodes/types';
import ranking from '@/modules/Dashboard/ranking';
import system from '@/modules/System';
import { AWARD_KEY } from '@/modules/Dashboard/constants';
import AwardItem from './AwardItem/index.vue';

@Component({
    components: {
        AwardItem,
    },
})
export default class AchievementAwards extends Vue {
    @Get(dashboard) private awards!: AchievementAwardType[];

    @Get(ranking) private limit!: number;

    @Get(system) private screenType!: string;

    get awardsList() {
        const hidableMilestones = [AWARD_KEY.topTravelSeller, AWARD_KEY.topTravelLeader,
            AWARD_KEY.topTravelOverall, AWARD_KEY.topTravelRecruiter];
        return this.awards.filter((award) => !hidableMilestones.includes(award.type));
    }

    get displayEmptyElement(): boolean {
        const mod = this.awardsList.length % 2;

        return mod > 0;
    }
}
