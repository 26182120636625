
import { Vue, Component, Prop } from 'vue-property-decorator';
import RanksElement from './RanksElement.vue';

@Component({
    components: {
        RanksElement,
    },
})
export default class DashboardSkeleton extends Vue {
    @Prop() private unlockAfterPhase!: boolean;
}
