import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { NewStartersOptionsType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { GetNewStartersSearchOptionsParamsType, GetNewStartersSearchOptionsResultType } from '@/api/graphQL/graphNodes/GetNewStartersSearchOptionsQuery';
import newStartersRepository from './services/newStartersRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'newStartersSearchOptions',
})
@AutoMutations
export class NewStartersSearchOptions extends VuexModule {
    private query: string = '';

    private loading: boolean = false;

    private options: NewStartersOptionsType[] = [];

    private minSearchLength: number = 3;

    @Mutation
    setQuery(val: string) {
        this.query = val;
    }

    @Mutation
    setOptions(val: NewStartersOptionsType[]) {
        this.options = val;
    }

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Action()
    async search(data: { type: string, timeFrame: string }) {
        const { type, timeFrame } = data;
        const { query } = this;

        try {
            this.setLoading(true);

            const params: GetNewStartersSearchOptionsParamsType = {
                date: timeFrame,
                type,
                query: query || null,
            };

            const result: GetNewStartersSearchOptionsResultType = await newStartersRepository
                .searchNewStartersOptions(params) as GetNewStartersSearchOptionsResultType;

            const { searchNewStarters } = result.profile;

            this.setOptions(searchNewStarters);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(NewStartersSearchOptions);
