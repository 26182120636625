// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { AgreementType, EventViewType, InvitationType } from './types';

export default class GetEventInvitationQuery extends
    AbstractQueryResource<GetEventInvitationResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($id: String!) {
            me {
                roles,
            }
            profile {
                shadow,
                agreements {
                    type,
                    agreed,
                    agreedAt,
                },
            },
            event(id: $id) {
                id,
                title,
                description,
                confirmed,
                address,
                videoUrl
                campaignDateFrom,
                campaignDateTo,
                eventDateFrom,
                eventDateTo,
                canceled,
                type,
                ambassador {
                    id,
                    firstName,
                    lastName,
                    phoneNumber,
                    email,
                    address
                },
                hostess {
                    id,
                    firstName,
                    lastName,
                    phoneNumber,
                    email,
                    address
                },
                allowGuestsToInvite,
                invitations {
                    id,
                    enrollee {
                        firstName,
                        lastName,
                    },
                    attending,
                    name,
                    email,
                    type
                },
                eventCode,
                shippingAddress {
                    title,
                    firstName,
                    lastName,
                    phoneNumber,
                    postcode,
                    address,
                    secondAddress,
                    city,
                    county,
                    country
                }
            },
            invitationForEvent(eventId: $id) {
                id,
                email,
                address,
                name,
                phoneNumber,
                invitedBy {
                    firstName,
                    lastName,
                    email,
                    phoneNumber,
                },
                enrollee {
                    firstName,
                    lastName,
                    email,
                },
                attending,
                type
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getEventInvitation';
    }
}

export type GetEventInvitationResultType = {
    me: {
        roles: string[];
    };
    event: EventViewType;
    profile: {
        shadow: boolean;
        agreements: AgreementType[];
    };
    invitationForEvent: InvitationType;
}
