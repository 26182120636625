import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { CountriesType } from '../Event/types';
import countriesRepository from './services/countriesRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'countries',
})
@AutoMutations
export class Countries extends VuexModule {
    private countries: CountriesType[] = [];

    private regions: CountriesType[] = [];

    private loading: boolean = false;

    @Mutation
    setCountries(value: CountriesType[]) {
        this.countries = [...value];
    }

    @Mutation
    setRegions(value: CountriesType[]) {
        this.regions = [...value];
    }

    @Mutation
    setLoading(value: boolean) {
        this.loading = value;
    }

    get countriesList(): CountriesType[] {
        return this.countries;
    }

    get regionsList(): CountriesType[] {
        return this.regions;
    }

    @Action()
    public async getCountries(token: string|null = null) {
        try {
            this.setLoading(true);

            const props: any = {};

            if (token) {
                props.token = token;
            }

            const res = await countriesRepository.getCountries(props);

            this.setCountries(res.countries);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async getRegions(token: string|null = null) {
        try {
            this.setLoading(true);

            const props: any = {};

            if (token) {
                props.token = token;
            }

            const res = await countriesRepository.getRegions(props);

            this.setRegions(res.regions);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(Countries);
