
import { Vue, Component } from 'vue-property-decorator';
import newStarters from '@/modules/NewStarters';
import rank from '@/modules/Rank';
import { Get } from '@/utils/vuex-module-mutators';
import LegItem from '@/projectComponents/enrolleeLegItem/Item/index.vue';
import { MilestoneType, NewStartersType, RankType } from '@/api/graphQL/graphNodes/types';
import { RankRequirementData } from '@/projectComponents/enrolleeLegItem/Item/RankRequirements/types';
import system from '@/modules/System';
import { PaginationParamsType } from '@/components/pagination/types';
import { scrollToTop } from '@plumtreesystems/utils';
import Achievement from '@/projectComponents/achievement/index.vue';
import resolveStatus from '@/projectComponents/achievement/resolveStatus';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import WildcardManager from '@/modules/Dashboard/services/wildcardManager';
import Requirement from './Requirement/index.vue';
import Header from '../Header/index.vue';
import QuickStartMilestones from './QuickStartMilestones/index.vue';

@Component({
    components: {
        LegItem,
        Requirement,
        Header,
        Achievement,
        QuickStartMilestones,
    },
})
export default class NewStartersContent extends Vue {
    @Get(newStarters) starters!: NewStartersType[];

    @Get(newStarters) private searchedLegs!: NewStartersType[];

    @Get(newStarters) loading!: boolean;

    @Get(newStarters) loadingInBackground!: boolean;

    @Get(rank) private ranks!: RankType[];

    @Get(rank, 'loading') private ranksLoading!: boolean;

    @Get(newStarters) private expandedNewStarters!: string[];

    @Get(newStarters) private activeSponsorDetailsModal!: string;

    @Get(newStarters) private displaySearchResults!: boolean;

    @Get(newStarters) private offset!: number;

    @Get(newStarters) private limit!: number;

    @Get(newStarters) private count!: number;

    @Get(system) private screenType!: string;

    get displayLoadMore(): boolean {
        if (this.displaySearchResults) {
            return false;
        }
        return this.limit <= this.count;
    }

    get displayLegs(): NewStartersType[] {
        if (this.displaySearchResults) {
            return this.searchedLegs;
        }
        return this.starters;
    }

    get isSizeMobile() {
        return this.screenType === 'mobile';
    }

    status(milestone: MilestoneType): string {
        return resolveStatus(milestone);
    }

    resolveValue(type: string): string {
        const res = WildcardManager.quickStartLabelResolver(type);

        return res.short;
    }

    getTLRequirements(leg: NewStartersType): RankRequirementData[] {
        return [
            {
                label: 'Personal Sales',
                metric: 'pv',
                current: `${leg.totals.pv}`,
                required: '200',
            },
            {
                label: 'Personal Team Sales',
                metric: 'gv',
                current: `${leg.totals.gv}`,
                required: '1800',
            },
            {
                label: 'Active Personal Partners',
                metric: 'active_on_file_direct_ambassadors',
                current: `${leg.personalRecruitsCount}`,
                required: '4',
            },
        ];
    }

    getSS2Requirements(leg: NewStartersType): RankRequirementData[] {
        const current = leg.superStarterTwoTotals ? leg.superStarterTwoTotals.pv || 0 : 0;

        return [
            {
                label: 'Sales in 4 weeks',
                metric: 'pv',
                current: `${current}`,
                required: `${200}`,
            },
        ];
    }

    getQuickStartList(milestones: MilestoneType[], region: string|null): MilestoneType[] {
        if (region === COUNTRY_SELECT_OPTIONS.gb) {
            return milestones.filter((item) => (item.key.includes('uk') && !item.key.includes('non_uk'))
             || !item.key.includes('uk'));
        }

        return milestones.filter((item) => item.key.includes('non_uk') || !item.key.includes('uk'));
    }

    tlReached(leg: NewStartersType):boolean {
        const ss = this.getTLRequirements(leg);
        let res = true;
        if (leg.highestAchievedRank.power >= 20) {
            return res;
        }
        ss.forEach((item) => {
            if (Number(item.required) > Number(item.current)) {
                res = false;
            }
        });

        return res;
    }

    getNewStartersExpanded(leg: NewStartersType) {
        return !!this.expandedNewStarters.find((item) => item === leg.id);
    }

    handleExpandedContentToggle(val: string) {
        newStarters.toggleExpandedList(val);
    }

    handleSponsorDetailDialog(val: string) {
        newStarters.setActiveSponsorDetailsModal(val);
    }

    handleOpenOrders(id: string) {
        this.$router.push({ name: 'newPartnersOrders', params: { id } });
    }

    async handlePagination(props: PaginationParamsType) {
        const { offset } = props;
        await newStarters.getData({ loadInBackground: true, offset });
        scrollToTop('#app > div.App__Layout > div > div.Scene__Content.Scene__Content--noPadding > div > div.Ui__ExpandableListLayout');
    }
}
