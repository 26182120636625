
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import { scrollToTop } from '@plumtreesystems/utils';

@Component
export default class Scene extends Vue {
    @Prop({ default: false }) private withoutPadding!: boolean;

    @Prop({ default: false }) private alwaysActive!: boolean;

    @Prop({ required: true }) private route!: string;

    mounted() {
        scrollToTop('#app');
    }

    get displayCurrentContent() {
        const { route, $route } = this;
        return $route.name === route;
    }

    get classes() {
        return [
            'Scene__Content',
            { 'Scene__Content--noPadding': this.withoutPadding },
        ];
    }
}
