
import { Component, Vue } from 'vue-property-decorator';
import underInspection from '@/modules/NoPermission/underInspection';
import { Get } from '@/utils/vuex-module-mutators';
import { TEXT_FIELD_DISPLAY_TYPE } from '@/components/textField/constants';
import { TOOLTIP_NAME_OF_ACCOUNT_TOOLTIP } from '@/modules/constants';
import { BankingDetailsResolverType, LabelType } from '@/modules/types';
import { resolveBankingDetails } from '@/modules/labels';
import { ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { BankDetailsType } from '@/api/graphQL/graphNodes/types';
import FormFieldTooltip from '@/projectComponents/formFieldTooltip/index.vue';
import profile from '@/modules/Profile';

@Component({
    components: {
        FormFieldTooltip,
    },
})
export default class NoPermissionUnderInspection extends Vue {
    @Get(underInspection) private loading!: boolean;

    @Get(profile, 'originalData.region') private region!: string|null;

    @Get(underInspection) private data!: BankDetailsType

    @Get(underInspection) private formErrors!: ErrorType;

    get bankingDetails(): BankingDetailsResolverType {
        return resolveBankingDetails(this.region);
    }

    get ibanType(): string {
        if (this.bankingDetails.displayStyle === 'UK') {
            return TEXT_FIELD_DISPLAY_TYPE.bankAccountNumber;
        }

        return TEXT_FIELD_DISPLAY_TYPE.iban;
    }

    get nameOfAccountTooltip(): string {
        return TOOLTIP_NAME_OF_ACCOUNT_TOOLTIP;
    }

    get sortCodeType(): string|null {
        if (this.bankingDetails.displayStyle === 'UK') {
            return TEXT_FIELD_DISPLAY_TYPE.sortCode;
        }

        return TEXT_FIELD_DISPLAY_TYPE.swift;
    }

    get labels(): LabelType {
        return resolveBankingDetails(this.region).labels;
    }

    handleValue(value, key) {
        const res = ObjectProcessor.setPropertyByValue(key, value, this.data);
        underInspection.removeFormError(key);
        underInspection.setFormData(res);
    }

    async handleSubmit() {
        try {
            await underInspection.saveData();

            if (Object.keys(this.formErrors).length === 0) {
                this.$router.push({ name: 'dashboard' });
            }
        // eslint-disable-next-line no-empty
        } catch (e) {}
    }
}
