import { RankType } from '@/api/graphQL/graphNodes/types';

const nextRank: (power: number, ranks: RankType[]) => RankType|null = (power, ranks) => {
    const nextRankItem = ranks.find((item) => item.power > power);
    if (nextRankItem) {
        return nextRankItem;
    }

    return null;
};

export default nextRank;
