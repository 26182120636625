
import { Vue, Component, Watch } from 'vue-property-decorator';
import rank from '@/modules/Rank';
import { Get } from '@/utils/vuex-module-mutators';
import newStarters from '@/modules/NewStarters';
import newStartersSearchOptions from '@/modules/NewStarters/searchOptions';
import impersonation from '@/modules/Impersonation';
import Skeleton from '@/projectComponents/skeleton/List/index.vue';
import Content from './Content/index.vue';

@Component({
    components: {
        Content,
        Skeleton,
    },
})
export default class NewStarters extends Vue {
    @Get(rank, 'loading') private ranksLoading!: boolean;

    @Get(newStarters) private displaySearchResults!: boolean;

    @Get(newStarters, 'loaded') private newStartersLoaded!: boolean;

    @Get(newStarters, 'loading') private newStartersLoading!: boolean;

    @Get(newStartersSearchOptions) private searchLoading!: boolean;

    @Get(impersonation) private token!: string|null;

    get loading(): boolean {
        return this.ranksLoading || this.newStartersLoading || this.searchLoading;
    }

    @Watch('token')
    loadLeaderLegs() {
        newStarters.getData();
    }

    @Watch('displaySearchResults')
    loadData() {
        if (!this.newStartersLoaded && !this.displaySearchResults) {
            this.loadLeaderLegs();
        }
    }

    mounted() {
        this.clearDataForSearch();
        rank.getRanks();
        // TODO: remove after fixing impersonate
        newStarters.setOffset();
        newStarters.setDisplaySearchResults(false);
        // if (!this.displaySearchResults) {
        this.loadLeaderLegs();
        // }
    }

    clearDataForSearch() {
        newStarters.setSearchedLegs([]);
        newStartersSearchOptions.setOptions([]);
        newStarters.setStarters([]);
        newStarters.clearExpandedNewStarters();
        newStarters.setLoaded(false);
        newStarters.setDisplaySearchResults(false);
        newStartersSearchOptions.setQuery('');
    }
}
