
import { PV_THRESHOLD } from '@/modules/constants';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class AoFStatus extends Vue {
    @Prop() private val!: string|null;

    get classes() {
        return [
            'Status__Element',
            'AoFStatus__Element',
            this.calculateContainerColor(this.val),
        ];
    }

    get displayVal(): string {
        return this.val || '';
    }

    get YTitle(): string {
        return ` Have placed £${PV_THRESHOLD}+ of orders this month + AOF`;
    }

    get N0Title(): string {
        return ' Never been active';
    }

    get N1Title(): string {
        return ` Placed £${PV_THRESHOLD}+ of orders 1 month ago + AOF`;
    }

    get N2Title(): string {
        return ` Placed £${PV_THRESHOLD}+ of orders 2 months ago + AOF`;
    }

    get N3Title(): string {
        return ` Placed £${PV_THRESHOLD}+ of orders 3 months ago + Not AOF`;
    }

    get N4Title(): string {
        return ` Placed £${PV_THRESHOLD}+ of orders 4 months ago + Not AOF`;
    }

    get N5Title(): string {
        return ` Placed £${PV_THRESHOLD}+ of orders 5 or more months ago + Not AOF`;
    }

    calculateContainerColor(key: string|null): string {
        switch (key) {
        case 'N3':
        case 'N4':
        case 'N5':
            return 'Status__Element--insufficient';
        case 'N':
        case 'N1':
        case 'N0':
        case 'N2':
            return 'Status__Element--almost';
        case 'Y':
            return 'Status__Element--fulfilled';
        case null:
            return 'Status__Element--disabled';

        default:
            return '';
        }
    }
}
