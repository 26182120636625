export const EVENT_TYPE = {
    party: 'party',
    school: 'school',
    private: 'private',
    showcase: 'showcase',
    getTogether: 'getTogether',
    campaignLaunch: 'campaignLaunch',
    generalMeeting: 'generalMeeting',
};

export const EVENT_HOST_TYPE = {
    undefined: -1,
    me: 0,
    known: 1,
    new: 2,
    no: 3,
};

export const MAX_CAMPAIGN_LENGTH: number = 10;

export const EVENT_ATTENDANCE_STATUS = {
    tentative: 'tentative_invitation_response',
    declined: 'declined_invitation_response',
    yes: 'accepted_invitation_response',
};

export const EVENT_INVITATION_TYPE = {
    guest: 'guest_invitation',
    hostess: 'hostess_invitation',
    ambassador: 'partner_invitation',
};

export const EVENT_DATE_FORMAT = 'dddd, MMMM D';

export const EVENT_TIME_FORMAT = 'HH:mm';

export const EVENT_DESCRIPTION_MAX_LENGTH = 5000;
export const EVENT_DESCRIPTION_MAX_LENGTH_ERROR = 'Description is too long';
