// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { RankRequirementsType, RankType } from './types';

export default class GetDashboardStatsQuery extends
    AbstractQueryResource<GetDashboardStatsResultType> {
    protected getQuery(): string {
        return `query ${this.getName()}($runId: String!) {
            profile {
                stats(runId: $runId) {
                    metric,
                    value
                },
                rank(runId: $runId) {
                    id,
                }
            },
            ranks(runId: $runId) {
                id,
                label,
                power,
                requirements {
                    metric,
                    value
                },
                titleRequirements {
                    metric,
                    value
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getDashboardStats';
    }
}

export type GetDashboardStatsResultType = {
    profile: {
        stats: RankRequirementsType[];
        rank: {
            id:string
        }
    };
    ranks: RankType[]
}

export type GetDashboardStatsParamsType = {
    runId: string;
}
