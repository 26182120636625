
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { SelectOptionsType } from '@/components/select/types';
import leaderLegs from '@/modules/LeaderLegs';
import legsRenderingList from '@/modules/LeaderLegs/legsRenderingList';
import { Debounced } from '@/utils/debounced';
import { LeaderLegsType } from '@/api/graphQL/graphNodes/types';

@Component({
    components: {},
})
export default class LeaderLegsHeader extends Vue {
    @Sync(leaderLegs) private searchQuery!: string;

    @Get(leaderLegs) private searchLoading!: boolean;

    @Get(leaderLegs) private minSearchLength!: number;

    @Get(leaderLegs) private searchedLegsOptions!: LeaderLegsType[];

    @Get(leaderLegs) private displaySearchResults!: boolean;

    get searchOptions(): SelectOptionsType[] {
        return this.searchedLegsOptions.map((leg) => ({
            name: `${leg.firstName} ${leg.lastName}`,
            value: `${leg.firstName} ${leg.lastName}`,
        }));
    }

    get headerContainerClasses() {
        return [
            'Ui__Card',
            'pts-layout',
            'pts-gutter',
            'LeaderLegsHeader__HeaderContainer',
        ];
    }

    get searchContainerClasses() {
        return [
            'pts-layout-item',
            'pts-size-100',
            'LeaderLegsHeader__Container',
        ];
    }

    handleSelect(val) {
        leaderLegs.setSearchQuery(val.value);
        leaderLegs.setDisplaySearchResults(true);
        leaderLegs.search({ selectedSearch: true });
    }

    @Debounced(1000)
    handleChange() {
        if (this.searchQuery === '') {
            leaderLegs.setDisplaySearchResults(false);
            legsRenderingList.setSearchedLegs([]);
            leaderLegs.setSearchedOptionsLegs([]);
        }
        if (this.searchQuery.length >= this.minSearchLength) { leaderLegs.search(); }
    }
}
