
import { Vue, Component } from 'vue-property-decorator';
import profile from '@/modules/Profile';
import { Get } from '@/utils/vuex-module-mutators';
import impersonate from '@/modules/Impersonation';
import auth from '@/modules/Auth/index';

@Component
export default class Navigation extends Vue {
    @Get(profile, 'originalData.firstName') private firstName!: string;

    @Get(profile, 'originalData.lastName') private lastName!: string;

    get impersonating(): boolean {
        return impersonate.impersonating;
    }

    get isHostess(): boolean {
        return auth.isHostess;
    }
}
