
import { Vue, Component } from 'vue-property-decorator';
import Skeleton from '@/projectComponents/skeleton/List/index.vue';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { AchievementAwardType } from '@/api/graphQL/graphNodes/types';
import ranking from '@/modules/Dashboard/ranking';
import rankingSearch from '@/modules/Dashboard/rankingSearch';
import { PaginationParamsType } from '@/components/pagination/types';
import { Debounced } from '@/utils/debounced';
import { initialRankings } from '@/modules/Dashboard/defaults';
import AwardListItem from './AwardListItem/index.vue';

@Component({
    components: {
        Skeleton,
        AwardListItem,
    },
})
export default class AwardsList extends Vue {
    @Get(ranking) private loading!: boolean;

    @Get(ranking) private loadingInBackground!: boolean;

    @Get(ranking, 'limit') private rankingLimit!: number;

    @Get(ranking, 'skip') private rankingSkip!: number;

    @Get(ranking, 'total') private rankingTotal!: number;

    @Get(ranking) private data!: AchievementAwardType|null;

    @Get(rankingSearch, 'data') private searchData!: AchievementAwardType|null;

    @Get(rankingSearch) private minSearchLength!: number;

    @Get(rankingSearch, 'loading') private searchLoading!: boolean;

    @Get(rankingSearch, 'loaded') private searchLoaded!: boolean;

    @Get(rankingSearch, 'limit') private searchLimit!: number;

    @Get(rankingSearch, 'skip') private searchSkip!: number;

    @Get(rankingSearch, 'total') private searchTotal!: number;

    @Sync(rankingSearch) private query!: string;

    get title(): string {
        const { data } = this;
        if (data === null) {
            return '';
        }

        return data.title;
    }

    get type(): string {
        return this.$route.params.type;
    }

    get displaySearchData(): boolean {
        const {
            query, searchLoaded,
        } = this;

        return query !== '' && searchLoaded;
    }

    get displayData(): AchievementAwardType {
        const {
            displaySearchData, data, searchData,
        } = this;

        if (displaySearchData) {
            return searchData || initialRankings();
        }

        return data || initialRankings();
    }

    get limit(): number {
        const { displaySearchData, rankingLimit, searchLimit } = this;
        return displaySearchData ? searchLimit : rankingLimit;
    }

    get skip(): number {
        const { displaySearchData, rankingSkip, searchSkip } = this;
        return displaySearchData ? searchSkip : rankingSkip;
    }

    get total(): number {
        const { displaySearchData, rankingTotal, searchTotal } = this;
        return displaySearchData ? searchTotal : rankingTotal;
    }

    @Debounced(1000)
    handleChange(val) {
        if (val.length === 0) {
            rankingSearch.resetSearchData();
        }

        if (val.length < this.minSearchLength) return;

        rankingSearch.getRankings(this.type);
    }

    handlePagination(props: PaginationParamsType) {
        const { type } = this;
        ranking.setSkip(props.offset);
        ranking.getRankings({ type, loadInBackground: true });
    }

    async mounted() {
        ranking.getRankings({ type: this.type, loadInBackground: false });
    }

    beforeDestroy() {
        ranking.resetData();
        rankingSearch.resetSearchData();
    }
}
