import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ErrorType } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { ReportType, RunsType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { GetReportsParamsType, GetReportsResultType } from '@/api/graphQL/graphNodes/GetReportsQuery';
import dateManager from '@/utils/time';
import ReportsRepository from './services/reportsRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'reports',
})
@AutoMutations
export class Reports extends VuexModule {
    private loading: boolean = false;

    private run: string|null = null;

    private runs: RunsType[] = [];

    private reports: ReportType[] = [];

    private errors: ErrorType = {};

    private reportsFrom: string = dateManager.getFirstDayOfMonth(0, 'YYYY-MM-DDTHH:mm:ssZ');

    private reportsTo: string = dateManager.getLastDayOfMonth(0, 'YYYY-MM-DDTHH:mm:ssZ');

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setRuns(val: RunsType[]) {
        this.runs = [...val];
    }

    @Mutation
    public setReports(val: ReportType[]) {
        this.reports = [...val];
    }

    @Mutation
    public setRun(val: string|null) {
        this.run = val;
    }

    @Mutation
    public setErrors(val: ErrorType) {
        this.errors = { ...this.errors, ...val };
    }

    @Mutation
    public clearErrors() {
        this.errors = {};
    }

    // When sending T required for separation
    @Mutation
    public setReportsFrom(val: string = dateManager
        .getFirstDayOfMonth(0, 'YYYY-MM-DDTHH:mm:ssZ')) {
        this.reportsFrom = val;
    }

    @Mutation
    public setReportsTo(val: string = dateManager
        .getLastDayOfMonth(0, 'YYYY-MM-DDTHH:mm:ssZ')) {
        this.reportsTo = val;
    }

    @Action()
    async getReports() {
        try {
            this.setLoading(true);

            const params: GetReportsParamsType = {
                runId: this.run,
                filterDateFrom: '2023-07-30',
            };

            const result: GetReportsResultType = await ReportsRepository
                .getReports(params) as GetReportsResultType;

            const { participatedRuns, reports } = result.profile;
            const sortedRuns = participatedRuns
                .sort((a, b) => (dateManager.isAfter(a.from, b.from) ? -1 : 1));

            if (sortedRuns.length > 0 && this.run === null) {
                this.setRun(sortedRuns[0].id);
            }

            this.setReports(reports);
            this.setRuns(participatedRuns);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(Reports);
