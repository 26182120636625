
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { Debounced } from '@/utils/debounced';
import myCustomers from '@/modules/MyCustomers';
import system from '@/modules/System';
import { SelectOptionsType } from '@/components/select/types';
import { OrderType } from '@/api/graphQL/graphNodes/types';

@Component
export default class MyCustomersOrdersHeader extends Vue {
    @Sync(myCustomers) private searchQuery!: string;

    @Get(myCustomers) private searchLoading!: boolean;

    @Get(myCustomers) private minSearchLength!: number;

    @Get(myCustomers) private searchedOrdersOptions!: OrderType[];

    @Get(myCustomers) private displaySearch!: boolean;

    @Get(system) private screenType!: string;

    get customerId() {
        const { id } = this.$route.params;
        return id;
    }

    get searchOptions(): SelectOptionsType[] {
        return this.searchedOrdersOptions.map((order) => ({
            name: `#${order.id}`,
            value: `#${order.id}`,
        }));
    }

    get showSearch() {
        return this.screenType !== 'mobile' || this.displaySearch;
    }

    get searchContainerClasses() {
        return [
            'pts-layout-item',
            'pts-size-100',
            'MyCustomersOrdersHeader__Container',
            { 'MyCustomersOrdersHeader__Container--show': this.showSearch },
        ];
    }

    handleSelect(val) {
        myCustomers.setSearchQuery(val.value);
        myCustomers.setDisplaySearchResults(true);
        myCustomers.searchOrders({ enrolleeId: this.customerId, selectedSearch: true });
    }

    @Debounced(1000)
    handleChange() {
        if (this.searchQuery === '') {
            myCustomers.setDisplaySearchResults(false);
            myCustomers.setSearchedOrdersOptions([]);
            myCustomers.setSearchedOrders([]);
            myCustomers.getCustomerOrders({ enrolleeId: this.customerId });
        }
        if (this.searchQuery.length >= this.minSearchLength) {
            myCustomers.searchOrders({ enrolleeId: this.customerId });
        }
    }
}
