
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import dashboardStats from '@/modules/Dashboard/stats';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import { RankRequirementsType, RankType } from '@/api/graphQL/graphNodes/types';
import risingStars, { RisingStars } from '@/modules/RisingStars';
import { DIRECTION_ASC } from '@/modules/constants';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class ActiveOnFilePartners extends Vue {
    @Get(dashboard, 'data.activeOnFileDirectEnrollees') private activeOnFilePartners!: number;

    @Get(dashboardStats) private stats!: RankRequirementsType[];

    @Prop() private requirements!: RankRequirementType[];

    @Prop() private rank!: RankType;

    get realActiveOnFilePartners(): number {
        const foundItem = this.stats.find((item) => item.metric === this.requirement!.metric);

        if (foundItem) {
            return Number(foundItem.value);
        }

        return this.activeOnFilePartners;
    }

    get metric(): string {
        return RankRequirementManager.rankRequirementMetricConstants.activeOnFilePartners;
    }

    get metricOld(): string {
        return RankRequirementManager.rankRequirementMetricConstants.activeOnFilePartnersOld;
    }

    get title(): string {
        return RankRequirementManager.getRankRequirementLabel(this.metric).title;
    }

    get requirement(): RankRequirementType | null {
        const requirement = this.requirements
            .find((item) => item.metric === this.metric || item.metric === this.metricOld);

        return requirement || null;
    }

    get progressValue(): number|null {
        const x = this.realActiveOnFilePartners * 100;
        const { requirement } = this;

        if (!requirement) {
            return null;
        }

        if (Number(requirement.value) === 0) {
            return 0;
        }

        return x / Number(requirement.value);
    }

    get displayData(): boolean {
        const req = this.requirement;

        if (!req) {
            return false;
        }

        return true;
    }

    handleRedirect() {
        risingStars.setType(RisingStars.TYPE_TYPES.aof);
        risingStars.setDirection(DIRECTION_ASC);
        this.$router.push({ name: 'myTeam' });
    }
}
