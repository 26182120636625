
import { Vue, Component, Prop } from 'vue-property-decorator';
import LegItem from '@/projectComponents/enrolleeLegItem/Item/index.vue';
import { CGet, Get } from '@/utils/vuex-module-mutators';
import risingStarsLegs from '@/modules/RisingStars/leg';
import risingStars from '@/modules/RisingStars';
import { GenealogyEnrolleeType, RankType, RisingStarsType } from '@/api/graphQL/graphNodes/types';
import rank from '@/modules/Rank';
import RisingStarsStatusTooltipContent from '@/projectComponents/risingStarsStatusTooltipContent/index.vue';
// import resolveStatusType from
// '@/projectComponents/enrolleeLegItem/Item/services/resolveStatusType';
import calculateStatus from '../services/calculateStatus';

@Component({
    components: {
        LegItem,
        RisingStarsStatusTooltipContent,
    },
})
export default class RisingStarsLegsListItem extends Vue {
    @Prop({ required: true }) id!: string;

    @CGet(risingStarsLegs) data!: GenealogyEnrolleeType;

    @CGet(risingStarsLegs) loading!: boolean;

    @Get(risingStars) expandedRisingStars!: string[];

    @Get(rank) ranks!: RankType[];

    @Get(risingStars) activeSponsorDetailsModal!: string;

    get calculatedStatus(): string {
        // if (this.display150req) {
        // const { pv } = this.data.totals;
        // const percentage = pv >= 150 ? 100 : (pv * 100) / 150;
        // return resolveStatusType(percentage);
        //     return 'disabled';
        // }

        return calculateStatus(this.data, this.ranks, 'status');
    }

    get calculatedStatusProgress(): number {
        return Number(calculateStatus(this.data, this.ranks, 'percentage'));
    }

    // get display150req(): boolean {
    //     const { label } = this.data.rank;
    //     return label === 'Partner';
    // }

    getRisingStarsExpanded(leg: RisingStarsType) {
        return !!this.expandedRisingStars.find((item) => item === leg.id);
    }

    handleExpandedContentToggle(val: string) {
        risingStars.toggleExpandedList(val);
    }

    handleSponsorDetailDialog(val: string) {
        risingStars.setActiveSponsorDetailsModal(val);
    }
}
