import {
    Module, VuexModule, Mutation, getModule,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import store from '@/store';
import { GetAddressOptionsParamsType } from '@/api/rest/Resources/types';
import AddressOptionModule, { Option } from './addressOption';
import AddressOptionsRepository from '../AddressSelect/services/addressOptionsRepository';
import authLocalStorageManager from '../Auth/services/authLocalStorageManager';

@Module({
    namespaced: true, dynamic: true, store, name: 'registerBusinessAddressSelect',
})
@AutoMutations
export class RegisterBusinessAddressSelect extends VuexModule {
    private searchWord: string = '';

    private optionsIds: string[] = [];

    private loading: boolean = false;

    private selectedAddress: string = '';

    private resultSize: number = 0;

    get addressId() {
        return this.selectedAddress;
    }

    @Mutation
    setLoading(state: boolean) {
        this.loading = state;
    }

    @Mutation
    setSearchWord(val: string = '') {
        this.searchWord = val;
    }

    @Mutation
    setAddress(val: string = '') {
        this.selectedAddress = val;
    }

    @Mutation
    addOptionsIds(val: string) {
        if (this.optionsIds.indexOf(val) === -1) {
            this.optionsIds.push(val);
        }
    }

    @Mutation
    removeOptionsIds(val: string) {
        this.optionsIds = this.optionsIds.filter((item) => item !== val);
    }

    @Mutation
    clearOptionIds() {
        this.optionsIds = [];
    }

    @Mutation
    setResultSize(val: number) {
        this.resultSize = val;
    }

    @Action()
    async findAddresses(data: {token?: string, region?: string|null}) {
        try {
            this.setLoading(true);
            const { token } = data;

            const params: GetAddressOptionsParamsType = {
                token: token || authLocalStorageManager.getAuthToken() || '',
                text: this.searchWord,
            };

            const res = await AddressOptionsRepository.getAddressOptions(params);

            this.clearOptions();
            this.setResultSize(res.Items.length);

            res.Items.forEach((item) => {
                if (item.Type === 'Address') {
                    if (!AddressOptionModule.collection[item.Id]) {
                        const option = new Option();
                        option.data = {
                            description: item.Description,
                            highlight: item.Highlight,
                            text: item.Text,
                            type: item.Type,
                        };

                        option.searchPhrase = this.searchWord;
                        option.id = item.Id;

                        AddressOptionModule.addElement(option);
                    }
                    this.addOptionsIds(item.Id);
                }
            });
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    clearOptions() {
        this.optionsIds.forEach((item) => {
            this.removeOptionsIds(item);
            AddressOptionModule.clearOption(item);
        });
    }

    @Action()
    resetData() {
        this.setAddress();
        this.clearOptions();
        this.setSearchWord();
    }
}

export default getModule(RegisterBusinessAddressSelect);
