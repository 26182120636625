
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import eventCreate from '@/modules/Event/EventCreate';
import eventHostess from '@/modules/Event/Hostesses';
import { MyHostsType } from '@/api/graphQL/graphNodes/types';
import HostessSelect from '@/views/Events/Components/HostContent/hostessSelect.vue';
import Skeleton from '../../EventsSkeleton/Create/Host/index.vue';

@Component({
    components: {
        HostessSelect,
        Skeleton,
    },
})
export default class CreateEventKnownHostessList extends Vue {
    @Get(eventHostess) private loading!: boolean;

    @Get(eventHostess) private hostesses!: MyHostsType[];

    @Sync(eventCreate) private hostessFilter!: string;

    get filteredList() {
        return this.hostesses.filter((hostess) => (`${hostess.firstName} ${hostess.lastName}`)
            .toLowerCase()
            .includes(this.hostessFilter.toLowerCase()))
            .sort((a, b) => (`${a.firstName} ${a.lastName}` < `${b.firstName} ${b.lastName}` ? -1 : 1));
    }

    get groupedList() {
        return this.filteredList.reduce((groups, hostess) => {
            const groupName = (`${hostess.firstName} ${hostess.lastName}`).charAt(0);
            if (!groups[groupName]) {
                // eslint-disable-next-line no-param-reassign
                groups[groupName] = [];
            }
            const group = groups[groupName];
            group.push(hostess);

            return groups;
        }, {});
    }

    async beforeMount() {
        await eventHostess.getHostesses();
    }

    handleSelect(hostess) {
        eventCreate.setHostess(hostess);
        this.$router.push({ name: 'createEventDetails' });
    }
}
