import { FIELD_REQUIRED_MESSAGE, MINIMUM_TIME_FRAME_MESSAGE, MUST_CONTAIN_ADDRESS } from '@/utils/messages/formValidation';
import { CreateEventParamsType } from '@/api/graphQL/graphNodes/CreateEventQuery';
import dateManager from '@/utils/time';
import { descriptionLengthValidation } from './fieldUpdateValidations';
import { EventErrorType, EventFormType } from '../types';

const eventInMinimumTimePeriod: (from: string, to: string) => boolean = (from, to) => dateManager.isBefore(dateManager.getDateWithOffset(4, from, dateManager.getDayTimeFormat(), 'minute'), to);

const reusedValidation: (val: Partial<CreateEventParamsType>)
=> EventErrorType[] = (val) => {
    const errors: EventErrorType[] = [];

    if (!val.title) {
        errors.push({ key: 'title', val: FIELD_REQUIRED_MESSAGE });
    }

    if (val.shippingAddress && !val.shippingAddress.title) {
        errors.push({ key: 'shippingAddress.title', val: FIELD_REQUIRED_MESSAGE });
    }

    if (val.shippingAddress && !val.shippingAddress.firstName) {
        errors.push({ key: 'shippingAddress.firstName', val: FIELD_REQUIRED_MESSAGE });
    }

    if (val.shippingAddress && !val.shippingAddress.lastName) {
        errors.push({ key: 'shippingAddress.lastName', val: FIELD_REQUIRED_MESSAGE });
    }

    if (val.shippingAddress && !val.shippingAddress.phoneNumber) {
        errors.push({ key: 'shippingAddress.phoneNumber', val: FIELD_REQUIRED_MESSAGE });
    }

    if (val.campaignDateFrom && val.campaignDateTo
        && !eventInMinimumTimePeriod(val.campaignDateFrom, val.campaignDateTo)) {
        errors.push({ key: 'campaignDateTo', val: MINIMUM_TIME_FRAME_MESSAGE });
    }

    if (val.eventDateFrom && val.eventDateTo
        && !eventInMinimumTimePeriod(val.eventDateFrom, val.eventDateTo)) {
        errors.push({ key: 'eventDateTo', val: MINIMUM_TIME_FRAME_MESSAGE });
    }

    if (val.description) {
        const err = descriptionLengthValidation(val.description);

        if (err) {
            errors.push({ key: 'description', val: err });
        }
    }

    return errors;
};

const formEventValidation: (val: Partial<CreateEventParamsType>)
 => EventErrorType[] = (val) => {
     const errors: EventErrorType[] = [
         ...reusedValidation(val),
     ];

     if (!val.address) {
         errors.push({ key: 'address', val: MUST_CONTAIN_ADDRESS });
     }

     if (val.shippingAddress && !val.shippingAddress.addressLookup) {
         errors.push({ key: 'shippingAddress.addressLookup', val: FIELD_REQUIRED_MESSAGE });
     }

     return errors;
 };

const formEditValidation: (val: Partial<EventFormType>)
 => EventErrorType[] = (val) => {
     const errors: EventErrorType[] = [
         ...reusedValidation(val),
     ];

     //  if (!val.addressLookup) {
     //      errors.push({ key: 'addressLookup', val: MUST_CONTAIN_ADDRESS });
     //  }

     return errors;
 };

export { formEventValidation, formEditValidation };
