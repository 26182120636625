import { BusinessDetailsPropType } from './types';
// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';

export default class UpdateBusinessDetailsQuery extends
    AbstractQueryResource<UpdateBusinessDetailsResultType> {
    protected getQuery(): string {
        return `mutation ${this.getName()}($businessDetails: BusinessDetailsInput) {
            updateBusinessDetails(businessDetails: $businessDetails) {
                businessDetails {
                    businessAccount,
                    businessName,
                    businessAddress,
                    eoriNumber,
                    tinNumber,
                    vatNumber,
                }
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'updateBusinessDetails';
    }
}

export type UpdateBusinessDetailsResultType = {
    businessDetails: BusinessDetailsPropType
}
export type UpdateBusinessDetailsPropType = {
    businessDetails: BusinessDetailsPropType
}
