import { RankType } from '@/api/graphQL/graphNodes/types';

const ranks: RankType[] = [
    {
        id: '0',
        power: 10,
        label: 'Partner',
        requirements: [],
        titleRequirements: [],
    },
    {
        id: '1',
        power: 20,
        label: 'Team Leader',
        requirements: [],
        titleRequirements: [],
    },
    {
        id: '2',
        power: 30,
        label: 'Group Leader',
        requirements: [],
        titleRequirements: [],
    },
    {
        id: '3',
        power: 40,
        label: 'Divisional Leader',
        requirements: [],
        titleRequirements: [],
    },
    {
        id: '4',
        power: 50,
        label: 'Executive Leader',
        requirements: [],
        titleRequirements: [],
    },
];

const rank:(id: string) => RankType = (id) => ranks.find((rankElement) => rankElement.id === id)!;
export { ranks, rank };
