// eslint-disable-next-line import/no-cycle
import { AbstractQueryResource } from '../internal';
import { RisingStarsType } from './types';

export default class GetRisingStarsQuery extends
    AbstractQueryResource<GetRisingStarsResultType|GetRisingStarsSearchResultType> {
    protected getQuery(options = { type: '' }): string {
        const { type } = options;
        return `query ${this.getName()}(${type === '' ? '$sort: String!, $limit: Int!, $offset: Int!, $sortOrder: String!' : '$query: String!'}, $type: String!) {
            profile {
                ${type === '' ? 'risingStars(sort: $sort, limit: $limit, offset: $offset, type: $type, sortOrder: $sortOrder)'
        : 'searchRisingStars(query: $query, type: $type)'} {
                    id,
                    totals {
                        pv,
                    },
                    firstName,
                    lastName,
                    rank {
                        id,
                        label,
                        power,
                    },
                    payRank {
                        id,
                        label,
                        power,
                    },
                    aOFStatus,
                },
                ${type === '' ? 'risingStarsCount,' : ''}
            }
        }`;
    }

    protected getCacheCondition() {
        return null;
    }

    protected getName(): string {
        return 'getRisingStars';
    }
}

export type GetRisingStarsResultType = {
    profile: {
        risingStars: RisingStarsType[];
        risingStarsCount: number;
    }
}
export type GetRisingStarsSearchResultType = {
    profile: {
        searchRisingStars: RisingStarsType[];
    }
}

export type RisingStarsParamsType = {
    sort: string;
    limit: number;
    offset: number;
    type: string;
    sortOrder: string;
}

export type RisingStarsSearchParamsType = {
    query: string;
    type: string;
}
