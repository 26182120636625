
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import rank from '@/modules/Rank';
import downlineExplorer from '@/modules/DownlineExplorer';
import profile from '@/modules/Profile';
import impersonation from '@/modules/Impersonation';
import Skeleton from '@/projectComponents/skeleton/List/index.vue';
import businessShape from '@/modules/BusinessShape';
import Downline from './Downline/index.vue';

@Component({
    components: {
        Downline,
        Skeleton,
    },
})
export default class DownlineExplorer extends Vue {
    @Get(rank, 'loading') private ranksLoading!: boolean;

    @Get(downlineExplorer, 'loading') private downlineLoading!: boolean;

    @Get(downlineExplorer, 'loaded') private downlineLoaded!: boolean;

    @Get(downlineExplorer) private offset!: number;

    @Get(downlineExplorer) private searchLoading!: boolean;

    @Get(downlineExplorer) private displaySearchResults!: boolean;

    @Get(businessShape, 'loading') private businessShapeLoading!: boolean;

    @Get(profile, 'data.id') private myId!: string;

    @Get(impersonation) private token!: string|null;

    get loading() {
        return this.ranksLoading || this.downlineLoading || this.businessShapeLoading
         || this.searchLoading;
    }

    handleDataLoad() {
        businessShape.getAmbassadorsCounts(this.myId);
        rank.getRanks();
        this.loadExplorer();
    }

    loadExplorer() {
        downlineExplorer.getDownlineData({ id: this.myId, offset: this.offset });
    }

    @Watch('token')
    handleImpersonateChange() {
        this.handleDataLoad();
    }

    @Watch('displaySearchResults')
    loadData() {
        if (!this.downlineLoaded && !this.displaySearchResults) {
            this.loadExplorer();
        }
    }

    beforeMount() {
        // TODO: remove after fixing impersonate
        downlineExplorer.setOffset(0);
        this.handleDataLoad();
    }

    beforeDestroy() {
        downlineExplorer.setSearchedLegs([]);
        downlineExplorer.setSearchedOptionsLegs([]);
        downlineExplorer.clearDirectDownline();
        downlineExplorer.clearExpandedList();
        downlineExplorer.setLoaded(false);
        downlineExplorer.setDisplaySearchResults(false);
        downlineExplorer.setSearchQuery('');
    }
}
