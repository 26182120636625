import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action, ErrorType, ObjectProcessor } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { BusinessDetailsType } from '@/api/graphQL/graphNodes/types';
import { GetNoPermissionBusinessDetailsResultType } from '@/api/graphQL/graphNodes/GetNoPermissionBusinessDetailsQuery';
import addressSelect from '@/modules/AddressSelect';
import noPermissionRepository from './services/noPermissionRepository';
import { ObjectPropertyType } from '../types';
import { businessDetailsValidation } from '../Register/services/registrationFormValidation';
import { defaultPropBusinessDetails, initialBusinessDetails } from '../defaults';

@Module({
    namespaced: true, dynamic: true, store, name: 'noPermissionBusinessDetails',
})
@AutoMutations
export class NoPermissionBusinessDetails extends VuexModule {
    private loading: boolean = false;

    private data: BusinessDetailsType = initialBusinessDetails();

    private region: string = '';

    private formErrors: ErrorType = {};

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setFormError(payload: ObjectPropertyType) {
        this.formErrors = {
            ...this.formErrors,
            [payload.key]: payload.val,
        };
    }

    @Mutation
    public setFormErrors(errors: any) {
        this.formErrors = { ...errors };
    }

    @Mutation
    public clearFormErrors() {
        this.formErrors = {};
    }

    @Mutation
    public removeFormError(key) {
        const { formErrors } = this;
        delete formErrors[key];
        this.formErrors = { ...formErrors };
    }

    @Mutation
    public setFormData(data: Partial<BusinessDetailsType>) {
        this.data = ObjectProcessor.objectMerge(initialBusinessDetails(), data);
    }

    @Mutation
    public resetFormData() {
        this.data = { ...initialBusinessDetails() };
    }

    @Mutation
    public setBusinessAccount(val: boolean) {
        this.data.businessAccount = val;
    }

    @Mutation
    public clearPersonalBusinessDetails() {
        this.data.tinNumber = '';
    }

    @Mutation
    public clearBusinessBusinessDetails() {
        this.data = {
            ...this.data,
            eoriNumber: '',
            vatNumber: '',
            businessName: '',
            businessAddress: '',
            businessAddressLookup: '',
        };
    }

    @Mutation
    public setRegion(val: string) {
        this.region = val;
    }

    @Action()
    public clearData() {
        this.setLoading(false);
    }

    @Action()
    public async getData() {
        try {
            this.setLoading(true);
            const res: GetNoPermissionBusinessDetailsResultType = await noPermissionRepository
                .getBusinessDetails();

            const { businessDetails, region } = res.profile;
            this.setFormData(!businessDetails ? {} : ObjectProcessor
                .removeEmptyProperties(businessDetails));
            this.setRegion(region);
        } catch (e) {
            const errors = ErrorsProcessor.process(e);
            this.setFormErrors(errors.form);
        } finally {
            this.setLoading(false);
        }
    }

    @Action()
    public async saveData() {
        try {
            this.setLoading(true);

            if (this.data.businessAccount) {
                this.clearPersonalBusinessDetails();
            } else {
                this.clearBusinessBusinessDetails();
                addressSelect.resetData();
            }

            this.clearFormErrors();
            const { addressId } = addressSelect;

            const params = {
                businessDetails: ObjectProcessor.objectMerge(
                    defaultPropBusinessDetails(),
                    ObjectProcessor.removeEmptyProperties({
                        ...this.data,
                        businessAddressLookup: addressId === '' ? null : addressId,
                    }),
                ),
            };

            const formErrors = businessDetailsValidation(params.businessDetails, this.region);

            if (formErrors.length > 0) {
                formErrors.forEach((error) => this.setFormError(error));
            } else {
                await noPermissionRepository.updateBusinessDetails(params);
            }
        } catch (e) {
            const errors = ErrorsProcessor.process(e);
            this.setFormErrors(errors.form);
            if (Object.keys(this.formErrors).length === 0) {
                throw new Error();
            }
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(NoPermissionBusinessDetails);
