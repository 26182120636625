class PhoneNumberManager {
    private phoneRegex = /\(\+\d+\)/g;

    getPhoneNumberObject(val: string): { code: string, number: string } {
        const wrappedCode = val.match(this.phoneRegex);

        if (wrappedCode && wrappedCode.length > 0) {
            return {
                code: String(wrappedCode[0])
                    .replace('(', '')
                    .replace(')', ''),
                number: val.replace(wrappedCode[0], ''),
            };
        }

        return {
            code: '',
            number: val,
        };
    }
}

export default new PhoneNumberManager();
