import rankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementDataType, RankRequirementType } from '../types';

const rankRequirementList: RankRequirementDataType[] = [
    {
        id: '0',
        values: [
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.pv,
                value: '120',
            },
        ],
    },
    {
        id: '1',
        values: [
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.pv,
                value: '120',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.uv,
                value: '1800',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.personalRecruits,
                value: '4',
            },
        ],
    },
    {
        id: '2',
        values: [
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.pv,
                value: '240',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.gv,
                value: '1800',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.ov,
                value: '6000',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.personalRecruits,
                value: '4',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants
                    .qualifiedFrontlineLeaders,
                value: '2',
            },
        ],
    },
    {
        id: '3',
        values: [
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.pv,
                value: '240',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.gv,
                value: '1800',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.ov,
                value: '18000 ',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.personalRecruits,
                value: '4',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants
                    .qualifiedFrontlineLeaders,
                value: '4',
            },
        ],
    },
    {
        id: '4',
        values: [
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.pv,
                value: '240',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.gv,
                value: '1800',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.ov,
                value: '30000',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants.personalRecruits,
                value: '4',
            },
            {
                metric: rankRequirementManager.rankRequirementMetricConstants
                    .qualifiedFrontlineLeaders,
                value: '8',
            },
        ],
    },
];

const rankRequirement: (id: string) => RankRequirementType[] = (id) => {
    const rankRequirementItem = rankRequirementList
        .find((item: RankRequirementDataType) => item.id === id);
    if (rankRequirementItem) {
        return rankRequirementItem.values;
    }

    return [];
};

export { rankRequirement };
