
import { LeaderboardType } from '@/api/graphQL/graphNodes/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import env from '@/environment';
import WildcardManager from '@/modules/Dashboard/services/wildcardManager';

@Component
export default class AwardListItem extends Vue {
    @Prop() private data!: LeaderboardType;

    get icon(): string {
        const { position, previousPosition } = this.data;
        return WildcardManager.resolveDirectionIcon(position, previousPosition, env.BASE_URL);
    }

    get rankingIcon(): string {
        return `${env.BASE_URL}img/icons/awards/ranking.svg`;
    }
}
