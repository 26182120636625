import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { AchievementAwardType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { initialRankings } from './defaults';
import dashboardRepository from './services/dashboardRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'rankingSearch',
})
@AutoMutations
export class RankingSearch extends VuexModule {
    private data: AchievementAwardType|null = initialRankings();

    private total: number = 0;

    private limit: number = 10;

    private skip: number = 0;

    private loading: boolean = false;

    private loaded: boolean = false;

    private query: string = '';

    private minSearchLength: number = 3;

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoaded(val: boolean) {
        this.loaded = val;
    }

    @Mutation
    public setSkip(val: number) {
        this.skip = val;
    }

    @Mutation
    public resetSkip() {
        this.skip = 0;
    }

    @Mutation
    public setTotal(val: number) {
        this.total = val;
    }

    @Mutation
    public setRanking(val: AchievementAwardType|null = initialRankings()) {
        if (val) {
            this.data = { ...val };
        }

        this.data = val;
    }

    @Mutation
    public setQuery(val: string) {
        this.query = val;
    }

    @Action()
    public resetSearchData() {
        this.setLoaded(false);
        this.resetSkip();
        this.setTotal(0);
        this.setRanking();
        this.setQuery('');
    }

    @Action()
    public async getRankings(type: string) {
        try {
            this.setLoading(true);

            const { limit, skip, query } = this;

            const res = await dashboardRepository.getRankings({
                limit,
                skip,
                type,
                query,
            });

            this.setTotal(res.achievementAwardsCount);
            this.setRanking(res.achievementAwards);
            this.setLoaded(true);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            this.setLoading(false);
        }
    }
}

export default getModule(RankingSearch);
