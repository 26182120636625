var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"Navigation__Container"},[(!_vm.isDesktop)?_c('StatusBar'):_vm._e(),(_vm.isAmbassador)?_c('Section',{attrs:{"title":"Home"}},[_c('NavItem',{attrs:{"title":"Dashboard","route":{
                name: 'dashboard',
            }}}),_c('NavItem',{attrs:{"title":"Website","externalLink":_vm.websiteUrl,"disabled":!_vm.unlockedAfterStage(1)}})],1):_vm._e(),(_vm.isAmbassador)?_c('Section',{attrs:{"title":"Downline"}},[_c('NavItem',{attrs:{"title":"My New Partners","route":{
                name: 'newPartners',
            }}}),_c('NavItem',{attrs:{"title":"My Team","route":{
                name: 'myTeam',
            }}}),_c('NavItem',{attrs:{"title":"My Leaders","route":{
                name: 'myLeaders',
            }}}),_c('NavItem',{attrs:{"title":"My Business Shape","route":{
                name: 'myBusinessShape',
            }}}),_c('NavItem',{attrs:{"title":"My Downline","route":{
                name: 'myDownline',
            }}})],1):_vm._e(),(!_vm.impersonating && _vm.isAmbassador)?_c('Section',{attrs:{"title":"My Earnings"}},[_c('NavItem',{attrs:{"title":"My Commissions","route":{
                name: 'incomeSummary',
            }}}),_c('NavItem',{attrs:{"title":"My Reports","route":{
                name: 'reports',
            }}}),_c('NavItem',{attrs:{"title":"Store Credit","route":{
                name: 'storeCredit',
            },"disabled":!_vm.unlockedAfterStage(1)}})],1):_vm._e(),(_vm.isAmbassador)?_c('Section',{attrs:{"title":"My Orders"}},[(!_vm.impersonating)?_c('NavItem',{attrs:{"title":"Order now","externalLink":_vm.orderNowUrl,"disabled":!_vm.unlockedAfterStage(1)}}):_vm._e(),_c('NavItem',{attrs:{"title":"My Orders","route":{
                name: 'orders',
            },"disabled":!_vm.unlockedAfterStage(1)}}),(!_vm.impersonating)?_c('NavItem',{attrs:{"title":"My Held Orders","externalLink":_vm.myHeldOrdersUrl,"disabled":!_vm.unlockedAfterStage(1)}}):_vm._e(),(!_vm.impersonating)?_c('NavItem',{attrs:{"title":"My Reorder Requests","externalLink":_vm.myReorderRequestsUrl,"disabled":!_vm.unlockedAfterStage(1)}}):_vm._e()],1):_vm._e(),_c('Section',{attrs:{"title":"Events Planner"}},[(!_vm.impersonating && _vm.isAmbassador)?_c('NavItem',{attrs:{"title":"Create an Event","route":{
                name: 'createEvent',
            }}}):_vm._e(),_c('NavItem',{attrs:{"title":"My Events","route":{
                name: 'eventCalendar',
            }}})],1),(_vm.isAmbassador || (!_vm.isAmbassador && _vm.isDevelopmentEnvironment))?_c('Section',{attrs:{"title":"My Audience"}},[(_vm.isAmbassador)?_c('NavItem',{attrs:{"title":"Share sign-up link","route":{
                name: 'invite',
            }}}):_vm._e(),(_vm.isAmbassador && _vm.unlockedAfterStage(1))?_c('NavItem',{attrs:{"title":"Share shopping link","route":{
                name: 'shareShoppingLink',
            }}}):_vm._e(),(_vm.isAmbassador)?_c('NavItem',{attrs:{"title":"My Customers","route":{
                name: 'myCustomers',
            }}}):_vm._e(),(_vm.isDevelopmentEnvironment)?_c('NavItem',{attrs:{"title":"*** UI Components","route":{
                name: 'uiComponents',
            }}}):_vm._e(),(_vm.impersonating)?_c('NavItem',{attrs:{"title":"End Impersonation","route":{
                name: 'endImpersonation',
            }}}):_vm._e()],1):_vm._e(),(!_vm.impersonating)?_c('Section',{attrs:{"title":"My Account"}},[_c('NavItem',{attrs:{"title":"Personal Information","route":{
                name: 'profile',
            }}}),(_vm.isAmbassador)?_c('NavItem',{attrs:{"title":"Website Settings","externalLink":_vm.websiteSettingsUrl,"disabled":true}}):_vm._e(),(_vm.isAmbassador)?_c('NavItem',{attrs:{"title":"Newsletter Subscriptions","disabled":true}}):_vm._e(),_c('NavItem',{attrs:{"title":"Logout","route":{
                name: 'logout',
            }}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }