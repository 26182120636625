
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import profile from '@/modules/Profile';
import Auth from '@/modules/Auth';
import countries from '@/modules/Countries';
import profilePicture from '@/modules/Profile/profilePicture';
import Skeleton from '@/projectComponents/skeleton/Profile/index.vue';
import addressSelect from '@/modules/AddressSelect';
import profileBusinessAddressSelect from '@/modules/ProfileBusinessAddressSelect';
import Content from './Content/index.vue';
import TermsAndConditions from './Content/TermsAndConditions/index.vue';

@Component({
    components: {
        Content,
        Skeleton,
        TermsAndConditions,
    },
})
export default class Profile extends Vue {
    @Get(profile) private loading!: boolean;

    @Get(countries, 'loading') private countriesLoading!: boolean;

    beforeDestroy() {
        profilePicture.clearImageData();
        profile.clearFormErrors();
        profile.resetDataToOriginal();
    }

    mounted() {
        profilePicture.clearImageData();
        profile.clearFormErrors();
        profile.getProfile({
            isImpersonating: false, isAmbassador: Auth.isAmbassador, phoneValidation: true,
        });
        profile.setImageDialogOpen(false);
        profile.setCameraDialogOpen(false);
        countries.getRegions();
        profileBusinessAddressSelect.resetData();
        addressSelect.resetData();
    }

    get contentLoaded(): boolean {
        return !this.loading && !this.countriesLoading;
    }
}
