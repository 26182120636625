
import { Vue, Component, Prop } from 'vue-property-decorator';
import LegItem from '@/projectComponents/enrolleeLegItem/Item/index.vue';
import { CGet, CSync, Get } from '@/utils/vuex-module-mutators';
import leaderLegsLegs from '@/modules/LeaderLegs/leg';
import leaderLegs from '@/modules/LeaderLegs';
import legsRenderingList from '@/modules/LeaderLegs/legsRenderingList';
import { GenealogyEnrolleeType, RankType } from '@/api/graphQL/graphNodes/types';
import rank from '@/modules/Rank';
import system from '@/modules/System';
import calculateStatus from '@/projectComponents/enrolleeLegItem/Item/RankRequirements/LeaderLegsDetails/services/calculateStatus';
import LeaderLegsStatusTooltipContent from '@/projectComponents/leaderLegsStatusTooltipContent/index.vue';
import env from '@/environment';

@Component({
    components: {
        LegItem,
        LeaderLegsStatusTooltipContent,
    },
})
export default class LeaderLegsListItem extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop({ required: true }) private firstInLevel!: boolean;

    @CGet(leaderLegsLegs) private parentId!: string;

    @CGet(leaderLegsLegs) private level!: number;

    @CGet(leaderLegsLegs) private data!: GenealogyEnrolleeType;

    @CGet(leaderLegsLegs) private loading!: boolean;

    @CGet(leaderLegsLegs) private legs!: string[];

    @CSync(leaderLegsLegs) private openLevelLegs!: boolean;

    @Get(legsRenderingList) private expandedLegs!: string[];

    @Get(leaderLegs) private expandedLeaderLegs!: string[];

    @Get(leaderLegs) private displaySearchResults!: boolean;

    @Get(leaderLegs) private qualifiedLeaderBranchIds!: string[];

    @Get(rank) private ranks!: RankType[];

    @Get(system) private screenType!: string;

    @Get(leaderLegs) private activeSponsorDetailsModal!: string;

    get branchesContainerClasses() {
        return [
            'LeaderLegsListItem__QualifiedLeaderBranchesContainer',
            { 'LeaderLegsListItem__QualifiedLeaderBranchesContainer--fulfilled': this.hasQualifiedLeader },
        ];
    }

    get hasQualifiedLeader(): boolean {
        return !!this.qualifiedLeaderBranchIds.find((item) => item === this.id);
    }

    get teamAmbassador(): RankType {
        return this.ranks
            .find((rankItem: RankType) => rankItem.label === 'Team Leader')!;
    }

    get calculatedStatus(): string {
        return calculateStatus(this.teamAmbassador, this.data, 'status');
    }

    get calculatedStatusProgress(): number {
        return Number(calculateStatus(this.teamAmbassador, this.data, 'percentage'));
    }

    get isMobile(): boolean {
        return this.screenType === 'mobile';
    }

    get showLeg() {
        return !!this.expandedLeaderLegs.find((item) => item === this.parentId);
    }

    get baseUrl() {
        return env.BASE_URL;
    }

    getLeaderLegsExpanded(leg: GenealogyEnrolleeType): boolean {
        return !!this.expandedLeaderLegs.find((item) => item === leg.id);
    }

    getLevelsExpanded(leg:GenealogyEnrolleeType): boolean {
        return !!this.expandedLegs.find((item) => item === leg.id);
    }

    getExpandedLegsIcon(expanded: boolean): string {
        return `${env.BASE_URL}img/icons/${expanded ? 'one_person_arrow_up.svg' : 'one_person_arrow_down.svg'}`;
    }

    handleExpandedContentToggle(val: string) {
        leaderLegs.toggleExpandedList(val);
    }

    handleExpandedLevelsToggle() {
        legsRenderingList.toggleExpandedList(this.id);
    }

    handleSponsorDetailDialog(val: string) {
        leaderLegs.setActiveSponsorDetailsModal(val);
    }

    mounted() {
        leaderLegsLegs.loadDetails(this.id);
        leaderLegsLegs.loadLegs({
            id: this.id,
            level: this.level + 1,
            search: this.displaySearchResults,
        });
    }
}
