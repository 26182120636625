import {
    Module, VuexModule, getModule, Mutation,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import { AutoMutations } from '@/utils/vuex-module-mutators';
import store from '@/store';
import { AchievementAwardType } from '@/api/graphQL/graphNodes/types';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import { initialRankings } from './defaults';
import dashboardRepository from './services/dashboardRepository';

@Module({
    namespaced: true, dynamic: true, store, name: 'ranking',
})
@AutoMutations
export class Ranking extends VuexModule {
    private data: AchievementAwardType|null = initialRankings();

    private total: number = 0;

    private limit: number = 10;

    private skip: number = 0;

    private loading: boolean = false;

    private loadingInBackground: boolean = false;

    @Mutation
    public setLoading(val: boolean) {
        this.loading = val;
    }

    @Mutation
    public setLoadingInBackground(val: boolean) {
        this.loadingInBackground = val;
    }

    @Mutation
    public setSkip(val: number) {
        this.skip = val;
    }

    @Mutation
    public resetSkip() {
        this.skip = 0;
    }

    @Mutation
    public setTotal(val: number) {
        this.total = val;
    }

    @Mutation
    public setRanking(val: AchievementAwardType|null = initialRankings()) {
        if (val) {
            this.data = { ...val };
        }

        this.data = val;
    }

    @Action()
    public async resetData() {
        this.resetSkip();
        this.setRanking();
        this.setTotal(0);
    }

    @Action()
    public async getRankings(params: { type: string, loadInBackground: boolean }) {
        const { loadInBackground } = params;
        try {
            if (loadInBackground) {
                this.setLoadingInBackground(true);
            } else {
                this.setLoading(true);
            }

            const { limit, skip } = this;
            const { type } = params;

            const res = await dashboardRepository.getRankings({
                limit,
                skip,
                type,
                query: '',
            });

            this.setTotal(res.achievementAwardsCount);
            this.setRanking(res.achievementAwards);
        } catch (e) {
            ErrorsProcessor.process(e);
        } finally {
            if (loadInBackground) {
                this.setLoadingInBackground(false);
            } else {
                this.setLoading(false);
            }
        }
    }
}

export default getModule(Ranking);
