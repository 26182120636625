import dateManager from '@/utils/time';
import {
    RegisterHostessType, RegisterAmbassadorFormType, BankDetailsType, BusinessDetailsPropType,
} from '@/api/graphQL/graphNodes/types';
import { BAD_DISPLAY_DATE_FORMAT, FIELD_BAD_PHONE_FORMAT, FIELD_REQUIRED_MESSAGE } from '@/utils/messages/formValidation';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import { PROFILE_BUSINESS_DETAILS_ERROR } from '@/modules/Profile/formValidation';
import resolveRegionRequirement from '@/modules/Countries/services/resolveRegionRequirement';
import { ValidationErrorType } from '../../types';
import { PASSWORD_REPEAT, AGE_RESTRICTION } from '../messages';

const bankDetailsValidation:
    (val: BankDetailsType) =>
    ValidationErrorType[] = (val) => {
        const errors: ValidationErrorType[] = [];

        if (!val.bankAccountName) {
            errors.push({ key: 'bankDetails.bankAccountName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.bankAccountNumber) {
            errors.push({ key: 'bankDetails.bankAccountNumber', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.bankSortCode) {
            errors.push({ key: 'bankDetails.bankSortCode', val: FIELD_REQUIRED_MESSAGE });
        }

        return errors;
    };

const businessDetailsValidation:
(val: BusinessDetailsPropType, region: string) =>
ValidationErrorType[] = (val, region) => {
    const requiredBusinessDetails = resolveRegionRequirement(region);
    const errors: ValidationErrorType[] = [];

    if (val.businessAccount) {
        if (!val.eoriNumber && requiredBusinessDetails.indexOf('eoriNumber') > -1) {
            errors.push({ key: 'businessDetails.eoriNumber', val: FIELD_REQUIRED_MESSAGE });
        }
        if (!val.vatNumber && requiredBusinessDetails.indexOf('vatNumber') > -1) {
            errors.push({ key: 'businessDetails.vatNumber', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!(val.businessName && val.businessAddress)
            && ((!!val.businessAddressLookup && !val.businessName) || (
                !val.businessAddressLookup && !!val.businessName
            ))) {
            errors.push({ key: 'businessDetails.businessAddressLookup', val: PROFILE_BUSINESS_DETAILS_ERROR });
            errors.push({ key: 'businessDetails.businessName', val: PROFILE_BUSINESS_DETAILS_ERROR });
        }
    } else if (!val.tinNumber && requiredBusinessDetails.indexOf('tinNumber') > -1) {
        errors.push({ key: 'businessDetails.tinNumber', val: FIELD_REQUIRED_MESSAGE });
    }

    return errors;
};

const registrationFormValidation:
    (val: RegisterAmbassadorFormType, passwordCheck: boolean) =>
    ValidationErrorType[] = (val, passwordCheck) => {
        let errors: ValidationErrorType[] = [];
        const currentDate = dateManager.getCurrentDate('YYYY-MM-DD');
        const isGb = val.region === COUNTRY_SELECT_OPTIONS.gb;

        if (!val.firstName) {
            errors.push({ key: 'firstName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.lastName) {
            errors.push({ key: 'lastName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.password && passwordCheck) {
            errors.push({ key: 'plainPassword', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.repeatPassword && passwordCheck) {
            errors.push({ key: 'repeatPassword', val: FIELD_REQUIRED_MESSAGE });
        }

        if (val.password !== val.repeatPassword && passwordCheck) {
            errors.push({ key: 'repeatPassword', val: PASSWORD_REPEAT });
        }

        if (!val.termsChecked) {
            errors.push({ key: 'termsChecked', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.phoneNumber) {
            errors.push({ key: 'phoneNumber', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.phoneNumberCode) {
            errors.push({ key: 'phoneNumberCode', val: FIELD_REQUIRED_MESSAGE });
        }

        if (val.phoneNumber && val.phoneNumberCode) {
            const plus = `(${val.phoneNumberCode})${val.phoneNumber}`.match(/\+/g);

            if (plus && plus.length > 1) {
                errors.push({ key: 'phoneNumber', val: FIELD_BAD_PHONE_FORMAT });
            }
        }

        if (!val.addressLookup) {
            errors.push({ key: 'addressLookup', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.birthDate) {
            errors.push({ key: 'birthDate', val: FIELD_REQUIRED_MESSAGE });
        }

        if (val.birthDate && !dateManager.isValid(val.birthDate, 'DD-MM-YYYY')) {
            errors.push({ key: 'birthDate', val: BAD_DISPLAY_DATE_FORMAT });
        }

        if (val.birthDate && dateManager.isValid(val.birthDate, 'DD-MM-YYYY')) {
            const selectedDate = dateManager.getDateWithOffset(18, val.birthDate.split('-').reverse()
                .join('-'), 'YYYY-MM-DD', 'y');

            if (dateManager.isBefore(currentDate, selectedDate)) {
                errors.push({ key: 'birthDate', val: AGE_RESTRICTION });
            }
        }

        if (!isGb) {
            errors = [...errors, ...businessDetailsValidation(val.businessDetails, val.region)];
        }

        // errors = [...errors, ...bankDetailsValidation(val.bankDetails)];

        return errors;
    };

const hostessRegistrationFormValidation:
    (val: RegisterHostessType) => ValidationErrorType[] = (val) => {
        const errors: ValidationErrorType[] = [];
        // const currentDate = dateManager.getCurrentDate('YYYY-MM-DD');
        // const selectedDate = dateManager.getDateWithOffset(18, val.birthDate, 'YYYY-MM-DD', 'y');

        if (!val.firstName) {
            errors.push({ key: 'firstName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.lastName) {
            errors.push({ key: 'lastName', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.password) {
            errors.push({ key: 'plainPassword', val: FIELD_REQUIRED_MESSAGE });
        }

        if (!val.repeatPassword) {
            errors.push({ key: 'repeatPassword', val: FIELD_REQUIRED_MESSAGE });
        } else if (val.password !== val.repeatPassword) {
            errors.push({ key: 'repeatPassword', val: PASSWORD_REPEAT });
        }

        if (!val.termsChecked) {
            errors.push({ key: 'termsChecked', val: FIELD_REQUIRED_MESSAGE });
        }

        // if (!val.birthDate) {
        //     errors.push({ key: 'birthDate', val: FIELD_REQUIRED_MESSAGE });
        // } else if (dateManager.isBefore(currentDate, selectedDate)) {
        //     errors.push({ key: 'birthDate', val: AGE_RESTRICTION });
        // }

        return errors;
    };

export {
    registrationFormValidation, hostessRegistrationFormValidation, bankDetailsValidation,
    businessDetailsValidation,
};
