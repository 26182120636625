
import { PV_THRESHOLD } from '@/modules/constants';
import { Vue, Component } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class BusinessShapeLegend extends Vue {
    get pvThreshold() {
        return PV_THRESHOLD;
    }
}
