import {
    Module, VuexModule, Mutation, getModule,
} from 'vuex-module-decorators';
import { CustomAction as Action } from '@plumtreesystems/utils';
import store from '@/store';
import { MeType } from '@/api/graphQL/graphNodes/types';
import { GetMeResultType } from '@/api/graphQL/graphNodes/GetMeQuery';
import ErrorsProcessor from '@/utils/responseErrorsProcessor';
import profile from '@/modules/Profile';
import settings from '@/modules/Settings';
import DetailsRepository from './services/detailsRepository';

const defaultMe: () => MeType = () => ({
    userName: '',
    roles: [],
});

@Module({
    namespaced: true, dynamic: true, store, name: 'auth',
})
class Auth extends VuexModule {
    myAccountLoaded: boolean = false;

    myAccountLoading: boolean = false;

    myAccount: MeType = defaultMe();

    authFailMode: string = '';

    authFailContacts: string[] = [];

    authFailMessage: string = '';

    authFailHandled: boolean = false;

    authenticationInProgress = false;

    authenticated = false;

    paidForKit: boolean = false;

    suspended: boolean = false;

    get isAmbassador(): boolean {
        const { roles } = this.myAccount;

        return roles.includes('ROLE_AMBASSADOR');
    }

    // hostess role is based on the rank. It should be impossible to have hostess and
    // ambassador ranks at the same time.
    get isHostess(): boolean {
        const { roles } = this.myAccount;

        return roles.includes('ROLE_HOSTESS');
    }

    get isCustomer(): boolean {
        const { roles } = this.myAccount;

        return roles.includes('ROLE_CUSTOMER');
    }

    @Mutation
    setAuthFailHandled(value: boolean) {
        this.authFailHandled = value;
    }

    @Mutation
    setAuthFailMode(mode: string) {
        this.authFailMode = mode;
    }

    @Mutation
    setAuthFailContacts(contacts: string[]) {
        this.authFailContacts = [...contacts];
    }

    @Mutation
    setAuthFailTenantLabel(msg: string) {
        this.authFailMessage = msg;
    }

    @Mutation
    setMyAccountLoaded(value: boolean) {
        this.myAccountLoaded = value;
    }

    @Mutation
    setMyAccount(account: MeType) {
        this.myAccount = { ...account };
    }

    @Mutation
    setAuthenticationInProgress(val: boolean) {
        this.authenticationInProgress = val;
    }

    @Mutation
    addRole(val: string) {
        if (this.myAccount.roles.indexOf(val) === -1) {
            this.myAccount.roles = [...this.myAccount.roles, val];
        }
    }

    @Mutation
    setAuthenticated(val: boolean) {
        this.authenticated = val;
    }

    @Mutation
    setPaidForKit(val: boolean) {
        this.paidForKit = val;
    }

    @Mutation
    setMyAccountLoading(val: boolean) {
        this.myAccountLoading = val;
    }

    @Mutation
    setSuspended(val: boolean) {
        this.suspended = val;
    }

    @Action()
    async setupUserDetails(isImpersonating: boolean) {
        this.setMyAccountLoading(true);
        await Promise.all([
            this.loadMe(),
            profile.getProfile({ isImpersonating, isAmbassador: this.isAmbassador }),
        ])
            .then(() => this.setMyAccountLoaded(true))
            .finally(() => this.setMyAccountLoading(false));
    }

    @Action()
    async loadMe() {
        try {
            const response: GetMeResultType = await DetailsRepository.getMe();
            settings.setEventGuestsInviteOptionEnabled(response.eventGuestsInviteOptionEnabled);
            this.setMyAccount(response.me);
            profile.setShopUrl(response.profile.shopUrl);
            profile.setShopLoginUrl(response.profile.shopLoginUrl);
            profile.setVanityUrl(response.profile.vanityUrl);
            this.setSuspended(response.profile.suspension);
        } catch (e) {
            ErrorsProcessor.process(e);
            throw e;
        }
    }

    @Action()
    public async checkAmbassadorKit() {
        try {
            const res = await DetailsRepository.checkAmbassadorKit();
            this.setPaidForKit(res.profile.paidForKit);
        } catch (e) {
            ErrorsProcessor.process(e);
        }
    }

    @Action()
    public async checkSuspension() {
        try {
            const res = await DetailsRepository.getSuspended();
            this.setSuspended(res.profile.suspension);
        } catch (e) {
            ErrorsProcessor.process(e);
        }
    }
}

export default getModule(Auth);
