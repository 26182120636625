import { ResolverBusinessDetailsType } from '../types';

const details: ResolverBusinessDetailsType[] = [
    {
        businessAccount: true,
        businessName: 'Sarah business',
        businessAddress: '61 St Giles, Oxford OX1 3LZ, United Kingdom',
        businessAddressLookup: '',
        id: '0',
        eoriNumber: null,
        tinNumber: null,
        vatNumber: null,
    },
];

const businessDetails: (id: string) => null|ResolverBusinessDetailsType = (id) => {
    const res = details.find((item) => item.id === id);
    return res || null;
};

export {
    businessDetails,
};
