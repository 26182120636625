
import { Vue, Component } from 'vue-property-decorator';
import eventCreate from '@/modules/Event/EventCreate';
import { EVENT_TYPE } from '@/modules/Event/constants';

import OptionItemLayout from '@/projectComponents/optionItemLayout/index.vue';
import { Get } from '@/utils/vuex-module-mutators';
import env from '@/environment';

@Component({
    components: {
        OptionItemLayout,
    },
})
export default class CreateEventType extends Vue {
    @Get(eventCreate, 'eventData.type') private type!: string;

    get eventPartyType() {
        return EVENT_TYPE.party;
    }

    get eventPrivateType() {
        return EVENT_TYPE.school;
    }

    get baseUrl() {
        return env.BASE_URL;
    }

    get eventsTypesUnlocked() {
        return true;
    }

    handleTypeSelect(type: string) {
        eventCreate.setEventType(type);
        this.$router.push({ name: 'createEventHostess' });
    }

    mounted() {
        const { redirect } = this.$route.query;

        if (redirect) {
            eventCreate.setRedirectUrl(decodeURIComponent(redirect as string));
        }
    }

    created() {
        eventCreate.clearEventCreate();
    }
}
