
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import system from '@/modules/System';
import Skeleton from '@/projectComponents/skeleton/List/index.vue';
import myCustomers from '@/modules/MyCustomers';
import Header from './CustomersHeader/index.vue';
import CustomersList from './CustomersList/index.vue';

@Component({
    components: {
        Header,
        CustomersList,
        Skeleton,
    },
})
export default class MyCustomers extends Vue {
    @Get(myCustomers) private customersLoading!: boolean;

    @Get(myCustomers) private customersSearchLoading!: boolean;

    @Get(myCustomers) private offset!: number;

    @Get(myCustomers) private displayCustomersSearchResults!: boolean;

    @Get(system) private screenType!: string;

    get contentLoaded(): boolean {
        return this.customersLoading || this.customersSearchLoading;
    }

    get isMobile() {
        return this.screenType === 'mobile';
    }

    handleSearchToggle() {
        myCustomers.toggleShowCustomersSearch();
    }

    mounted() {
        // TODO: remove after fixing impersonate
        myCustomers.setOffset();
        myCustomers.getMyCustomers({ offset: 0 });
    }

    beforeDestroy() {
        myCustomers.setCustomers([]);
        myCustomers.setSearchedCustomersOptions([]);
        myCustomers.clearExpandedCustomersList();
        myCustomers.setDisplayCustomersSearchResults(false);
        myCustomers.setCustomerSearchQuery('');
    }
}
